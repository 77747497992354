import React from 'react';

import PropTypes from 'prop-types';

import {yellow, green, red, lightGray} from '@renofi/utils/src/colors';
import {SUCCESS, WARNING} from '@renofi/utils/src/status';

import {Badge} from './';

function StatusBadge({text, ...props}) {
  const color = () => {
    if (!text) return lightGray;
    if (text === SUCCESS) return green;
    if (text === WARNING) return yellow;
    return red;
  };

  return (
    <Badge size={14} {...props} color={color()} large>
      {text || 'incomplete'}
    </Badge>
  );
}

StatusBadge.propTypes = {
  text: PropTypes.string,
};

export default StatusBadge;
