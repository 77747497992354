import React from 'react';

import PropTypes from 'prop-types';
import {Text} from 'rebass';

import {gray} from '@renofi/utils/src/colors';

function Label({children, ...props}) {
  return (
    <Text fontSize={14} lineHeight="16px" color={gray} {...props}>
      {children}
    </Text>
  );
}

Label.propTypes = {
  children: PropTypes.node,
};

export default Label;
