import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {Flex, Box, Text} from 'rebass';
import {isNil, isNumber, pick} from 'lodash';

import {AddressField, Button, Toggle, CheckIcon} from '@renofi/components';
import {AddressInputs} from '@renofi/modules';
import {toCurrency} from '@renofi/utils/src/format';
import {gray, lightGray} from '@renofi/utils/src/colors';
import {useUpdateScenarioPersonal} from '@renofi/api';

const Estimate = ({id, leadAddress, initialValue}) => {
  const [location, setLocation] = useState(leadAddress);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [value, setValue] = useState(initialValue);
  const {updateScenarioPersonal} = useUpdateScenarioPersonal({
    advisorApp: true,
  });

  const {streetAddress, city, zipCode, state, county} = location;

  function onStreetChange(value) {
    setLocation({...location, streetAddress: value});
  }

  function onAddressSelect(location) {
    setLocation(location);
  }

  async function onSubmit() {
    setLoading(true);
    setValue(null);
    setSuccess(false);

    const rsp = await updateScenarioPersonal({
      variables: {
        id,
        zipCode,
        location: pick(location, [
          'city',
          'county',
          'state',
          'streetAddress',
          'streetAddressShort',
          'zipCode',
        ]),
      },
    });
    const {estimatedHomeValue} = rsp?.data?.updateScenarioPersonal.lead || {};
    setValue(estimatedHomeValue);
    setLoading(false);
    setSuccess(true);
  }

  return (
    <>
      <AddressField
        xLarge
        value={streetAddress}
        onSelect={onAddressSelect}
        onChange={(value, err) => onStreetChange(value, err)}
      />

      <AddressInputs
        zipCode={zipCode}
        state={state}
        city={city}
        county={county}
        onChange={() => {}}
        {...location}
      />

      <Flex width="100%" mt={24} justifyContent="space-between">
        <Flex minHeight={26} alignItems="center" mr={36}>
          <Toggle show={success}>
            <Box minWidth={30} mr={16} mt="3px">
              <CheckIcon
                width={30}
                height={30}
                style={{filter: 'grayscale(1)'}}
              />
            </Box>
            <Text fontSize={16} fontWeight={100} color={gray} lineHeight="20px">
              {isNumber(value)
                ? 'Done. It may take a few minutes for the changes to be visible in Sales Force'
                : 'Address saved without estimation. Estimation not found. It may take a few minutes for the changes to be visible in Sales Force'}
            </Text>
          </Toggle>
        </Flex>

        <Flex width={160} justifyContent="flex-end">
          <Button onClick={onSubmit} loading={loading} disabled={loading}>
            Save
          </Button>
        </Flex>
      </Flex>

      <Flex mt={16} pt={24} css={{borderTop: 'solid 1px #DEDEDE'}}>
        <Text fontSize={18} color={value ? gray : lightGray}>
          Estimated home value is:{' '}
          <Flex mt={16} fontSize={32} color={value ? 'black' : lightGray}>
            <Toggle show={isNumber(value)}>
              <strong>${toCurrency(value)}</strong>
            </Toggle>
            <Toggle show={isNil(value)}>---</Toggle>
          </Flex>
        </Text>
      </Flex>
    </>
  );
};

Estimate.propTypes = {
  id: PropTypes.string,
};

export default Estimate;
