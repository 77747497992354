import React, {memo} from 'react';

import {filter, isArray} from 'lodash';
import PropTypes from 'prop-types';
import {Text, Flex} from 'rebass';

import {Button, Toggle} from '@renofi/components';
import {red, yellow} from '@renofi/utils/src/colors';
import {FAILURE, WARNING} from '@renofi/utils/src/status';

import Label from '../Label';

function Value({
  children,
  label,
  color,
  status,
  hide,
  small,
  onEdit,
  css,
  ...props
}) {
  const textColor =
    color || status === FAILURE ? red : status === WARNING ? yellow : null;
  const placeholder = '-';

  if (hide) return null;

  const isEmpty =
    (isArray(children) &&
      filter(children, (el) => el === null || el === undefined).length) ||
    !children;

  return (
    <>
      <Text
        css={{fontWeight: small ? 800 : 100, ...css}}
        fontSize={small ? 14 : 24}
        lineHeight={small ? '18px' : '26px'}
        mb={small ? 0 : 10}
        color={textColor}
        {...props}>
        <Toggle show={Boolean(label)}>
          <Flex justifyContent="space-between" alignItems="center">
            <Label mb={onEdit ? 10 : '5px'}>{label}</Label>
            <Toggle show={Boolean(onEdit)}>
              <Button mb={10} xxSmall secondary onClick={onEdit}>
                Edit
              </Button>
            </Toggle>
          </Flex>
        </Toggle>
        {isEmpty ? placeholder : children}
      </Text>
    </>
  );
}

Value.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  color: PropTypes.string,
  small: PropTypes.bool,
  css: PropTypes.object,
  status: PropTypes.string,
  hide: PropTypes.bool,
  onEdit: PropTypes.func,
};

export default memo(Value);
