import {useEffect, useRef} from 'react';

import {getItem} from '@renofi/utils';

import {THIRTY_SECONDS} from './constants';

export default function useWatchJwtCookie({onAuthError}) {
  const cached = useRef();

  const onJwtInterval = () => {
    const jwt = getItem('jwt');
    const loggedOut = Boolean(cached.current && !jwt);
    if (loggedOut) {
      onAuthError();
    }
    cached.current = jwt;
  };

  useEffect(() => {
    let timeoutId;
    cached.current = getItem('jwt');

    timeoutId = setInterval(onJwtInterval, THIRTY_SECONDS);
    onJwtInterval();

    return () => clearInterval(timeoutId);
  }, []);
}
