import React from 'react';

import {useLocation} from 'react-router-dom';
import {Box, Flex, Text} from 'rebass';
import PropTypes from 'prop-types';

import {Button} from '@renofi/components';
import {blue, darkBlue, darkGray, fadeGreen} from '@renofi/utils/src/colors';

import {Footer} from './PreApplication.styled';
import {ReactComponent as DoneIcon} from './img/done.svg';

const SuccessAlert = ({close}) => {
  const {state} = useLocation();
  return (
    <Flex flexDirection="column">
      <Flex
        pt={50}
        pb={30}
        px={[15, 100]}
        flexDirection="column"
        alignItems="center"
        justifyContent="center">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          css={{
            height: '100px',
            width: '100px',
            padding: '4px',
            borderRadius: '50%',
            backgroundColor: fadeGreen,
            border: `4px solid ${darkBlue}`,
            backgroundClip: 'content-box',
            margin: '0 auto',
          }}>
          <DoneIcon />
        </Box>
        <Text
          mt={25}
          fontSize={24}
          textAlign="center"
          lineHeight="28px"
          color={darkGray}>
          Worksheet generated and sent to: {state?.email}
        </Text>
      </Flex>
      <Footer>
        <Flex flexDirection={['column', 'row']} justifyContent="flex-end">
          <Button xSmall bgColor={blue} onClick={close}>
            Done
          </Button>
        </Flex>
      </Footer>
    </Flex>
  );
};

SuccessAlert.propTypes = {
  close: PropTypes.func.isRequired,
};

export default SuccessAlert;
