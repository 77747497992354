import React from 'react';

import {Box, Flex} from 'rebass';
import {useHistory, useParams} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {Button, BackIcon, Toggle} from '@renofi/components';

import {scenarioVar} from '../api/cache/scenario';

import Estimate from './Estimate';

const EstimatePage = () => {
  const {id} = useParams();
  const history = useHistory();
  const scenario = useReactiveVar(scenarioVar);
  const {streetAddress, city, zipCode, state, county, estimatedHomeValue} =
    scenario?.lead || {};

  function onBack() {
    history.push(`/${id}`);
  }

  return (
    <Box width={['100%', '800px']} maxWidth={1160} mx="auto" px={[15, 0]}>
      <Flex alignItems="center">
        <Toggle show={Boolean(id)}>
          <Box mr={16}>
            <Button ml={-14} mt="5px" transparent small onClick={onBack}>
              <BackIcon />
            </Button>
          </Box>
        </Toggle>
        <h1 style={{marginTop: 42, marginBottom: 36}}>
          Home value estimation form
        </h1>
      </Flex>

      <Estimate
        id={id}
        cont
        initialValue={estimatedHomeValue}
        leadAddress={id ? {streetAddress, city, zipCode, state, county} : {}}
      />
    </Box>
  );
};

export default EstimatePage;
