import {head, last} from 'lodash';

const getLoanOptionsCalculations = (
  calculationsByRate = [],
  loanAmountDesiredForRenovation = 0,
) => {
  if (calculationsByRate.length < 1) return {};

  const firstCalculationByRate = head(calculationsByRate);
  const lastCalculationByRate = last(calculationsByRate);
  const firstCalculatedMaxLoanAmount =
    firstCalculationByRate.maxLoanAmountOffered;
  const lastCalculatedMaxLoanAmount =
    lastCalculationByRate.maxLoanAmountOffered;

  const maxLoanAmount = Math.max(
    firstCalculatedMaxLoanAmount,
    lastCalculatedMaxLoanAmount,
  );
  const diffLoanAmount =
    firstCalculatedMaxLoanAmount !== lastCalculatedMaxLoanAmount;

  const borrowingPercentDiff =
    1 - maxLoanAmount / loanAmountDesiredForRenovation;
  const canBeBorrowed = loanAmountDesiredForRenovation <= maxLoanAmount;

  return {
    length: calculationsByRate.length,
    firstRate: firstCalculationByRate.rate,
    lastRate: lastCalculationByRate.rate,
    firstPayment: firstCalculationByRate.monthlyPaymentForDti,
    lastPayment: lastCalculationByRate.monthlyPaymentForDti,
    firstCalculatedMaxLoanAmount,
    lastCalculatedMaxLoanAmount,
    maxLoanAmount,
    diffLoanAmount,
    borrowingPercentDiff,
    canBeBorrowed,
  };
};

export default getLoanOptionsCalculations;
