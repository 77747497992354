import {Toaster} from 'react-hot-toast';

import {darkGray, fadeGray} from '@renofi/utils/src/colors';

const FailedAlert = () => {
  return (
    <Toaster
      toastOptions={{
        style: {
          background: darkGray,
          color: fadeGray,
          maxWidth: '500px',
        },
      }}
    />
  );
};

export default FailedAlert;
