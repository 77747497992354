import React, {useReducer, createContext} from 'react';

import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Box} from 'rebass';

import {Notification, Wrapper} from './styled';

export const NotificationContext = createContext();

const NotificationsProvider = ({top = 0, ...props}) => {
  const [notifications, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION':
        return [...state, {...action.payload}];
      case 'REMOVE_NOTIFICATION': {
        return state.filter((el) => el.id !== action.id);
      }
      default:
        return state;
    }
  }, []);

  return (
    <NotificationContext.Provider value={dispatch}>
      <Wrapper flexDirection="column" top={top}>
        {notifications.map((notification, index) => {
          const type = get(notification, 'type', 'info');
          const message = get(notification, 'message', null);
          const css = get(notification, 'css', {});
          const isVisible = Boolean(notification);

          return (
            <Notification
              key={notification.id || index}
              visible={isVisible}
              type={type}
              css={{...props?.css, ...css}}
              {...props}>
              <Box px={16}>{message}</Box>
            </Notification>
          );
        })}
      </Wrapper>
      {props.children}
    </NotificationContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  top: PropTypes.number,
  children: PropTypes.node,
};

export default NotificationsProvider;
