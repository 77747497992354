import React, {useEffect, useState} from 'react';

import {Box, Flex} from 'rebass';
import {useParams, useHistory, Route, useRouteMatch} from 'react-router-dom';
import {useReactiveVar} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {Loader, Toggle} from '@renofi/components';
import {red} from '@renofi/utils/src/colors';

import Header from '../Header';
import {onScenarioUpdate} from '../api/callbacks';
import {scenarioVar} from '../api/cache/scenario';
import adminRenovationScenarioPosMock from '../api/queries/adminRenovationScenarioPos.mock';
import adminRenovationScenarioMock from '../api/queries/adminRenovationScenario.mock';
import useAdminRenovationScenario from '../api/hooks/useAdminRenovationScenario';

import Summary from './Summary';
import Property from './Property';
import Income from './Income';
import Debt from './Debt';
import Renovation from './Renovation';
import LoanOptionsModal from './LoanOptionsModal';

function Admin() {
  const scenario = useReactiveVar(scenarioVar);
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const {path, url} = useRouteMatch();
  const {lead} = scenario;

  useEffect(() => sendEvent('Advisors/Main-Details-Page'), []);

  const params = useParams();
  const isMock = params?.id === 'mock';
  const queryScenario = useAdminRenovationScenario({}, isMock);

  useEffect(() => {
    (async () => {
      if (!params.id || params?.mock) return;

      if (isMock && params.name) {
        const mock =
          params.name === 'pos'
            ? adminRenovationScenarioPosMock
            : adminRenovationScenarioMock;
        onScenarioUpdate(mock.result.data.adminRenovationScenario);
      } else {
        setLoading(true);
        await queryScenario({id: params.id});
        setLoading(false);
      }
    })();
  }, [params.id]);

  return (
    <>
      <Box
        width={['100%', 'calc(100% - 60px)']}
        maxWidth={1320}
        mx="auto"
        px={[15, 0]}
        pt={30}>
        <Header />

        <Toggle show={!lead?.id || loading}>
          <Flex justifyContent="center" pt={120}>
            <Loader size={1.5} color={red} />
          </Flex>
        </Toggle>

        <Toggle show={lead?.id && !loading}>
          <Summary
            onLoanProductClick={() => history.push(`${url}/loan-options`)}
          />
          <Property />
          <Income />
          <Debt />
          <Renovation />
        </Toggle>
      </Box>
      <Route path={`${path}/loan-options`}>
        <LoanOptionsModal />
      </Route>
    </>
  );
}

export default Admin;
