import React from 'react';

import {useRouteMatch, useHistory} from 'react-router-dom';
import {Box, Flex} from 'rebass';
import {isFinite, isNull, isEmpty} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {enums} from '@renofi/api';
import {Button, Table, Th, Td} from '@renofi/components';
import {toPercent, toCurrency} from '@renofi/utils/src/format';
import {getEnvironmentUrl} from '@renofi/utils';

import {isPersonalLoan, isRefi} from '../../utils/calcPawFields';
import getLoanOptionsCalculations from '../../utils/getLoanOptionsCalculations';
import {Grid} from '../styled';
import {scenarioVar} from '../../api/cache/scenario';
import useCurrentUser from '../../api/hooks/useCurrentUser';
import {Value} from '../../components';

import {ReactComponent as CalculatorIcon} from './img/calculator.svg';
import {ReactComponent as Download} from './img/download.svg';
import MissingSalesForceFieldsTooltip from './MissingSalesForceFieldsTooltip';
import MatchProblemsTooltip from './MatchProblemsTooltip/MatchProblemsTooltip';

const LoanOptions = () => {
  const scenario = useReactiveVar(scenarioVar);
  const {isMissingSalesForceFields, currentUser, loading} = useCurrentUser();
  const history = useHistory();
  let {url} = useRouteMatch();
  const calcAppUrl = getEnvironmentUrl('calculator', '4005');
  const {lead, productOptions} = scenario;
  const leadCreditScore = enums.get(lead.creditScore);
  const maxCreditScore = leadCreditScore?.split('-')[1];
  const dti = lead.monthlyDebt / lead.monthlyIncome;
  const isLeadDataIncomplete =
    !lead.email || !(lead.zipCode && lead.city && lead.state);
  const isDownloadDisabled = isLeadDataIncomplete || isMissingSalesForceFields;

  return (
    <>
      <Box px={17} pb={16} pt={10}>
        <Grid>
          <Value label="Loan amount requested">
            ${toCurrency(lead.loanAmountDesiredForRenovation)}
          </Value>
          <Value label="Current LTV">
            {toPercent(lead.firstMortgageBalance / lead.homeValue, 2)}
          </Value>
          <Value label="Current DTI" status={lead.status.estimatedDti}>
            {isFinite(dti) ? toPercent(dti) : '-'}
          </Value>
          <Value label="Credit Score" status={lead.status.creditScore}>
            {lead?.realCreditScoreNumber || leadCreditScore}
          </Value>
        </Grid>
      </Box>

      <Table>
        <thead>
          <tr>
            <Th>Product</Th>
            <Th>Lender</Th>
            <Th>Loan Amount Needed</Th>
            <Th>Example APR</Th>
            <Th>Est. Monthly Payment</Th>
            <Th>Estimated DTI</Th>
            <Th>Max borrowing power</Th>
            <Th>Minimum FICO score required</Th>
            <Th>PCLTV</Th>
            <Th>ARV CLTV</Th>
            <Th>Priority</Th>
            <Th />
            <Th />
            <Th />
          </tr>
        </thead>
        <tbody>
          {productOptions.map((product, index) => {
            const {
              estimatedDti,
              arvCltv,
              peakCltv,
              maxPeakCltv,
              maxArvCltv,
              explainMatch,
              matchQuality,
              calculationsByRate,
              priority,
            } = product;
            const {
              length,
              firstRate,
              lastRate,
              firstPayment,
              lastPayment,
              firstCalculatedMaxLoanAmount,
              lastCalculatedMaxLoanAmount,
              maxLoanAmount,
              diffLoanAmount,
              borrowingPercentDiff,
              canBeBorrowed,
            } = getLoanOptionsCalculations(
              calculationsByRate,
              lead.loanAmountDesiredForRenovation,
            );

            const showMaxPeakCltv =
              !isNull(maxPeakCltv) && peakCltv > maxPeakCltv;
            const showMaxArvCltv = !isNull(maxArvCltv) && arvCltv > maxArvCltv;
            const showDownloadAndInfoIcons =
              !isRefi(product?.productType) &&
              !isPersonalLoan(product?.productType) &&
              !loading;

            return (
              <tr key={index}>
                <Td name="product">
                  {product.name}&nbsp;{product.repaymentPeriod}&nbsp;years
                </Td>

                <Td name="lender">{product.lender?.name}</Td>

                <Td name="loanAmountNeeded">
                  {`$${toCurrency(product.loanAmountNeeded)}`}
                </Td>

                <Td name="exampleAPR">
                  {length > 1 ? `${firstRate}%-${lastRate}%` : `${firstRate}%`}
                </Td>

                <Td name="estimatedMonthlyPayment">
                  {length > 1
                    ? `$${toCurrency(firstPayment)}-$${toCurrency(lastPayment)}`
                    : `$${toCurrency(firstPayment)}`}
                </Td>

                <Td name="estimatedDti">{toPercent(estimatedDti)}</Td>

                <Td
                  name="maxBorrowingPower"
                  warning={!canBeBorrowed && borrowingPercentDiff <= 0.1}
                  danger={!canBeBorrowed && borrowingPercentDiff > 0.1}>
                  <Flex flexDirection="column">
                    <Box>
                      {length > 1 && diffLoanAmount
                        ? `$${toCurrency(
                            lastCalculatedMaxLoanAmount,
                          )}-$${toCurrency(firstCalculatedMaxLoanAmount)}`
                        : `$${toCurrency(maxLoanAmount)}`}
                    </Box>

                    {!canBeBorrowed && (
                      <Box css={{whiteSpace: 'nowrap'}}>
                        {length > 1 && diffLoanAmount
                          ? `(-$${toCurrency(
                              lead.loanAmountDesiredForRenovation -
                                lastCalculatedMaxLoanAmount,
                            )}) (-$${toCurrency(
                              lead.loanAmountDesiredForRenovation -
                                firstCalculatedMaxLoanAmount,
                            )})`
                          : `(-$${toCurrency(
                              lead.loanAmountDesiredForRenovation -
                                maxLoanAmount,
                            )})`}
                      </Box>
                    )}
                  </Flex>
                </Td>

                <Td
                  danger={maxCreditScore < product.minCreditScore}
                  width="150px">
                  {product.minCreditScore}
                </Td>

                <Td name="peakCltv" danger={peakCltv > maxPeakCltv}>
                  <Flex flexDirection="column">
                    <Box>{toPercent(peakCltv)}</Box>

                    {showMaxPeakCltv ? (
                      <Box>({toPercent(maxPeakCltv)})</Box>
                    ) : null}
                  </Flex>
                </Td>

                <Td name="arvCltv" danger={arvCltv > maxArvCltv}>
                  <Flex flexDirection="column">
                    <Box>{toPercent(arvCltv)}</Box>

                    {showMaxArvCltv ? (
                      <Box>({toPercent(maxArvCltv)})</Box>
                    ) : null}
                  </Flex>
                </Td>

                <Td name="priority">
                  <Flex flexDirection="column">
                    <Box>{priority}</Box>
                  </Flex>
                </Td>

                <Td name="linkToCalculatorApp">
                  <Button
                    css={{height: 'auto'}}
                    secondary
                    transparent
                    target="_blank"
                    as="a"
                    href={`${calcAppUrl}/renovation/${lead.calculatorToken}/product/${product?.id}`}>
                    <Flex
                      alignItems="center"
                      justifyContent={['center', 'space-between']}>
                      <CalculatorIcon />
                    </Flex>
                  </Button>
                </Td>

                <Td name="preApplicationWorksheetLink">
                  {showDownloadAndInfoIcons ? (
                    <Flex alignItems="center" css={{gap: '12px'}}>
                      <Button
                        css={{height: 'auto'}}
                        secondary
                        transparent
                        onClick={() => history.push(`${url}/${product.id}`)}
                        disabled={isDownloadDisabled}>
                        <Flex
                          alignItems="center"
                          justifyContent={['center', 'space-between']}>
                          <Download />
                        </Flex>
                      </Button>

                      {isMissingSalesForceFields ? (
                        <Button css={{height: 'auto'}} secondary transparent>
                          <MissingSalesForceFieldsTooltip
                            currentUser={currentUser}
                          />
                        </Button>
                      ) : null}
                    </Flex>
                  ) : null}
                </Td>

                <Td name="matchProblemsIcon">
                  {!isEmpty(explainMatch) ? (
                    <MatchProblemsTooltip
                      index={index}
                      explainMatch={explainMatch}
                      matchQuality={matchQuality}
                    />
                  ) : null}
                </Td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default LoanOptions;
