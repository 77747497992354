import React from 'react';

import PropTypes from 'prop-types';

const MatchProblemsIcon = ({color}) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM10.9 6.5L10.9 8.7L13.1 8.7L13.1 6.5L10.9 6.5ZM10.9 17.5V10.9H13.1V17.5H10.9Z"
        fill={color}
      />
    </svg>
  );
};

MatchProblemsIcon.propTypes = {
  color: PropTypes.string,
};

export default MatchProblemsIcon;
