import {flow, isPlainObject, isEmpty, isBoolean, head} from 'lodash';

export const getInputsKeys = (object) => {
  const removeNullItems = ([, value]) => value !== null;

  const filtered = flow([
    Object.entries,
    (arr) => arr.filter(removeNullItems),
    Object.fromEntries,
  ])(object);

  const result = Object.keys(filtered).filter((key) => key !== '__typename');

  return result;
};

export const getFormattedValue = (value) => {
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  }

  if (isPlainObject(value)) {
    return JSON.stringify(value);
  }

  if (Array.isArray(value)) {
    if (isEmpty(value)) {
      return '';
    }

    const firstItem = head(value);

    if (isPlainObject(firstItem)) {
      return JSON.stringify(value);
    }

    return value.join(', ');
  }

  return value;
};
