import {useReactiveVar} from '@apollo/client';
import {useHistory} from 'react-router-dom';

import {enums} from '@renofi/api';
import {useRenovationUtils} from '@renofi/utils';
import {toCurrency, toPercent} from '@renofi/utils/src/format';
import {Panel} from '@renofi/components';

import {History, BoolValue, Value} from '../../components';
import {Grid} from '../styled';
import {scenarioVar} from '../../api/cache/scenario';

function Property() {
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;
  const {isAdu} = useRenovationUtils(lead);
  const history = useHistory();

  function onEditLocation() {
    history.push(`/${lead.id}/estimate`);
  }

  function getPropertyStatusMap() {
    if (isAdu) {
      return {
        owned: 'Adu on property I currently own',
        to_be_purchased: 'Adu on property I am looking to purchase',
      };
    }
    return {
      owned: 'I own the land',
      to_be_purchased: 'I am looking to purchase',
    };
  }

  return (
    <Panel dark background="white" title="Property details">
      <Grid>
        <Value
          css={{textTransform: 'capitalize'}}
          label="Location"
          onEdit={onEditLocation}
          status={lead.status.state}>
          {lead.streetAddress}, {lead.city}, {lead.state} {lead.zipCode}
          <History items={lead.history.state} />
        </Value>
        <Value label="Estimated home value">
          ${toCurrency(lead.estimatedHomeValue)}
        </Value>
        <Value label="Current value">${toCurrency(lead.homeValue)}</Value>
        <Value label="Loan amount">
          ${toCurrency(lead.loanAmountDesiredForRenovation)}
        </Value>
        <Value label="Expected ARV">
          ${toCurrency(lead.afterRenovatedValue)}
        </Value>
        <Value label="Purchase price">
          ${toCurrency(lead.propertyPurchasePrice)}
        </Value>
        <Value label="Downpayment">
          ${toCurrency(lead.estimatedDownpayment)}
        </Value>
        <Value label="Renovation cost">
          ${toCurrency(lead.renovationCost)}
        </Value>
        <Value label="First mortgage balance">
          ${toCurrency(lead.firstMortgageBalance)}
        </Value>
        <Value label="Second mortgage balance">
          ${toCurrency(lead.secondMortgageBalance)}
        </Value>
        <Value label="Reno cost to current property value ratio">-</Value>
        <Value label="Estimated ROI">
          {toPercent(
            (lead.afterRenovatedValue - lead.homeValue) / lead.renovationCost,
            2,
          )}
        </Value>

        <Value label="Property type" status={lead.status.propertyStructureType}>
          {enums.get(lead.propertyStructureType)}
          <History
            items={lead.history.propertyStructureType}
            formatFn={(value) => enums.get(value)}
          />
        </Value>

        <Value
          label="Property ownership status"
          status={lead.status.propertyOwnershipStatus}>
          {getPropertyStatusMap()[lead.propertyOwnershipStatus]}
        </Value>

        <Value
          label="Teardown of existing structure"
          status={lead.status.teardownOfExistingStructure}>
          <BoolValue value={lead.teardownOfExistingStructure} />
        </Value>

        <Value
          label="Anything left from original structure"
          status={lead.status.anythingLeftFromOriginalStructure}>
          <BoolValue value={lead.anythingLeftFromOriginalStructure} />
        </Value>

        <Value
          label="Pre-existing structures on the land"
          status={lead.status.preExistingStructuresOnLandPresent}>
          <BoolValue value={lead.preExistingStructuresOnLandPresent} />
        </Value>

        <Value
          label="Demolish existing structure"
          status={lead.status.demolishExistingStructure}>
          <BoolValue value={lead.demolishExistingStructure} />
        </Value>

        <Value label="Property subject" status={lead.status.loanSubject}>
          {enums.get(lead.loanSubject)}
          <History
            items={lead.history.loanSubject}
            formatFn={(value) => enums.get(value)}
          />
        </Value>

        <Value
          label="Property classification"
          status={lead.status.propertyUseType}>
          {enums.get(lead.propertyUseType)}
          <History
            items={lead.history.propertyUseType}
            formatFn={(value) => enums.get(value)}
          />
        </Value>

        <Value
          label="Property unit count"
          status={lead.status.propertyUnitCount}>
          {lead.propertyUnitCount}
        </Value>

        <Value label="Lender code" status={lead.status.sourceLenderCode}>
          {lead.sourceLenderCode}
        </Value>

        <Value
          label="External origin type"
          status={lead.status.leadExternalOriginType}>
          {lead.leadExternalOriginType}
        </Value>

        <Value label="Broker sourced" status={lead.status.brokerSourced}>
          <BoolValue value={lead.leadExternalOriginType} />
        </Value>
      </Grid>
    </Panel>
  );
}

export default Property;
