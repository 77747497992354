import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';

import {sendEvent} from '@renofi/analytics';
import {SearchField} from '@renofi/components';

import {SEARCH_BY_BORROWER} from '../api';

import SearchOption from './SearchOption';

function Search({floating}) {
  const [value, setValue] = useState('');
  const [searchByBorrower, {loading, data}] = useLazyQuery(SEARCH_BY_BORROWER);
  const history = useHistory();

  function onChange(newValue) {
    setValue(newValue);
  }

  async function onSearch(query) {
    await searchByBorrower({variables: {query}});
    sendEvent('Advisors/Search');
  }

  function onSelect({id}) {
    sendEvent('Advisors/Search-Results-Click');
    history.push(`/${id}`);
  }

  return (
    <SearchField
      autofocus
      xLarge
      floating={floating}
      loading={loading}
      onSearch={onSearch}
      onChange={onChange}
      onSelect={onSelect}
      value={value}
      options={data?.searchByBorrower}>
      {({onSelect}) =>
        data?.searchByBorrower.map(({lead = {}, lender = {}}) => {
          return (
            <SearchOption
              onClick={() => onSelect(lead)}
              key={lead.id}
              lead={lead}
              lender={lender}
            />
          );
        })
      }
    </SearchField>
  );
}

Search.propTypes = {
  floating: PropTypes.bool,
};

export default Search;
