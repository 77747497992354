import React from 'react';

import {Text} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import {isBoolean} from 'lodash';

import {enums} from '@renofi/api';
import {Panel} from '@renofi/components';

import {History, BoolValue, Value} from '../../components';
import {Grid} from '../styled';
import {scenarioVar} from '../../api/cache/scenario';

function Renovation() {
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;
  return (
    <Panel
      dark
      background="white"
      title="Renovation details"
      css={{marginBottom: 40}}>
      <Grid>
        <Value label="Expected start date">
          {enums.get(lead.renovationIdealStart)}
          <History
            items={lead.history.renovationIdealStart}
            formatFn={(value) => enums.get(value)}
          />
        </Value>
        <Value label="Expected purchase closing date">
          {enums.get(lead.estimatedDaysUntilClosingPurchase)}
          <History
            items={lead.history.estimatedDaysUntilClosingPurchase}
            formatFn={(value) => enums.get(value)}
          />
        </Value>
        <Value
          label="Who is performing the renovation?"
          status={lead.status.renovationPerformedByType}>
          {enums.get(lead.renovationPerformedByType)}
          <History
            items={lead.history.renovationPerformedByType}
            formatFn={(value) => enums.get(value)}
          />
        </Value>
        <Value label="Anything unique about your situation?">
          {lead.additionalRenovationDetails ? (
            <Text fontSize={16}>{lead.additionalRenovationDetails}</Text>
          ) : null}
        </Value>
        {isBoolean(lead.subcontractorsSecured) && (
          <Value label="Have you secured your contractor?">
            <BoolValue value={lead.subcontractorsSecured} />
            <History
              items={lead.history.subcontractorsSecured}
              formatFn={(value) => <BoolValue small value={value} />}
            />
          </Value>
        )}
        {isBoolean(lead.hasRenovationEstimate) && (
          <Value label="Do you have plans and estimates?">
            <BoolValue value={lead.hasRenovationEstimate} />
            <History
              items={lead.history.hasRenovationEstimate}
              formatFn={(value) => <BoolValue small value={value} />}
            />
          </Value>
        )}
        {isBoolean(lead.subcontractorsIdentified) && (
          <Value label="Have you identified subcontractors?">
            <BoolValue value={lead.subcontractorsIdentified} />
            <History
              items={lead.history.subcontractorsIdentified}
              formatFn={(value) => <BoolValue small value={value} />}
            />
          </Value>
        )}
        {isBoolean(lead.isLicensedContractor) && (
          <Value label="Are you a licensed contractor?">
            <BoolValue value={lead.isLicensedContractor} />
            <History
              items={lead.history.isLicensedContractor}
              formatFn={(value) => <BoolValue small value={value} />}
            />
          </Value>
        )}
      </Grid>
    </Panel>
  );
}

export default Renovation;
