import {
  createHistoryObject,
  createStatusesObject,
} from '@renofi/utils/src/status';

import {recalculateLoanProductMatches} from '../../utils/recalculateLoanProductMatches';
import {setScenario} from '../cache';

export function onScenarioUpdate(scenario) {
  const lead = scenario?.lead;
  if (!lead) {
    return scenario;
  }

  window.document.title = `Advisor | ${lead.lastName}, ${lead.firstName}`;
  setScenario({
    lead: {
      ...lead,
      history: createHistoryObject(lead.changeHistory),
      status: createStatusesObject(lead),
    },
    lender: scenario?.lender || {},
    productOptions: recalculateLoanProductMatches(scenario),
    loanPassLink: scenario?.loanPassLink,
  });

  return scenario;
}
