import {useMutation} from '@apollo/client';

import {REQUEST_WORKSHEET_HOME_EQUITY} from '../mutations/requestWorksheetHomeEquity';

export default function useRequestWorksheetHomeEquity() {
  const [requestWorksheetHomeEquity, {data, loading, error}] = useMutation(
    REQUEST_WORKSHEET_HOME_EQUITY,
  );
  const response = data?.requestWorksheetHomeEquity;
  return {requestWorksheetHomeEquity, response, loading, error};
}
