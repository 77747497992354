import {InMemoryCache} from '@apollo/client';

import {scenarioPolicy as scenario} from './scenario';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        scenario,
      },
    },
  },
});
