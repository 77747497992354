import React from 'react';

import {Text} from 'rebass';
import PropTypes from 'prop-types';

import {FAILURE, WARNING} from '@renofi/utils/src/status';
import {
  fadeYellow,
  darkRed,
  darkYellow,
  fadeGreen,
  darkGreen,
  fadeRed,
} from '@renofi/utils/src/colors';

import {HistoryItemWrapper} from './styled';

function HistoryItem({status, children, css}) {
  const bgColor =
    status === FAILURE ? fadeRed : status === WARNING ? fadeYellow : fadeGreen;
  const color =
    status === FAILURE ? darkRed : status === WARNING ? darkYellow : darkGreen;
  return (
    <HistoryItemWrapper bgColor={bgColor} css={css}>
      <Text
        css={{fontWeight: 300}}
        color={color}
        fontSize={14}
        lineHeight="18px">
        {children}
      </Text>
    </HistoryItemWrapper>
  );
}

HistoryItem.propTypes = {
  children: PropTypes.node,
  status: PropTypes.string,
  css: PropTypes.object,
};

export default HistoryItem;
