import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';
import {PatternFormat} from 'react-number-format';

import {StatusBadge, Toggle} from '@renofi/components';
import {
  OptionWrapper,
  ValuesWrapper,
} from '@renofi/components/src/SearchField/styled';

import {ReactComponent as LocationIcon} from './img/location.svg';
import {ReactComponent as PhoneIcon} from './img/phone.svg';
import {ReactComponent as EmailIcon} from './img/mail.svg';
import {ReactComponent as LenderIcon} from './img/lender.svg';
import SearchOptionValue from './SearchOptionValue';

const SearchOption = ({lead, lender, onClick}) => {
  const {firstName, lastName, city, state, zipCode, email} = lead;
  const {name} = lender || {};
  const locationText = city
    ? `${city}, ${state} ${zipCode}`
    : `${state} ${zipCode}`;
  return (
    <OptionWrapper onClick={onClick}>
      <Flex justifyContent="space-between">
        <Text fontSize={18} css={{fontWeight: 300}}>
          {firstName} {lastName}
        </Text>
        <StatusBadge text={lead.prequalResult} />
      </Flex>
      <ValuesWrapper>
        <SearchOptionValue
          text={locationText.replace(/null/, '')}
          icon={LocationIcon}
        />
        <SearchOptionValue icon={PhoneIcon}>
          <PatternFormat
            format="(###) ###-####"
            displayType="text"
            value={lead.phone}
          />
        </SearchOptionValue>
        <SearchOptionValue text={email} icon={EmailIcon} />
        <Toggle show={name}>
          <SearchOptionValue text={name} icon={LenderIcon} />
        </Toggle>
      </ValuesWrapper>
    </OptionWrapper>
  );
};

SearchOption.propTypes = {
  lead: PropTypes.object,
  lender: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

export default SearchOption;
