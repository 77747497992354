export const getMissingSalesForceFields = (data) => {
  const missingSalesForceFields = Object.entries(data || {})?.map(
    ([key, value]) => {
      if (!value) {
        return key;
      }
      return null;
    },
  );
  const ERRORS_MAPPER = [
    {
      name: 'Email',
      key: 'email',
    },
    {
      name: 'First name',
      key: 'firstName',
    },
    {
      name: 'Last name',
      key: 'lastName',
    },
    {
      name: 'Phone number',
      key: 'phoneNumber',
    },
  ];
  const fields = ERRORS_MAPPER.filter(({key}) =>
    missingSalesForceFields.includes(key),
  );
  return fields;
};
