import React from 'react';

import PropTypes from 'prop-types';
import {Text, Flex} from 'rebass';

import {InfoTooltip, PanelDark} from '@renofi/components';

import {getMissingSalesForceFields} from '../../utils/getMissingSalesForceFields';

const MissingSalesForceFieldsTooltip = ({currentUser}) => {
  const fields = getMissingSalesForceFields(currentUser);

  return (
    <InfoTooltip
      id={currentUser.id}
      place="left"
      width={750}
      css={{top: '-9px'}}>
      <PanelDark
        title={'Info: Your information is missing in Salesforce'}
        css={{}}>
        <Flex flexDirection={'column'}>
          <Text>
            Provide the following details for your Salesforce User to download
            Pre-application Worksheet:
          </Text>
          {fields.map(({name, key}) => (
            <li key={key}>{name}</li>
          ))}
        </Flex>
      </PanelDark>
    </InfoTooltip>
  );
};

MissingSalesForceFieldsTooltip.propTypes = {
  currentUser: PropTypes.object,
};

export default MissingSalesForceFieldsTooltip;
