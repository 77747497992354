import {gql} from '@apollo/client';

export const PRODUCTS = gql`
  query products {
    products {
      lender {
        id
        name
      }
      id
      name
      rates
      repaymentPeriod
      rateType
      maxPeakCltv
      maxArvCltv
      minLoanAmount
      maxLoanAmount
      minEstimatedClosingCost
      maxEstimatedClosingCost
      priority
      matchable
    }
  }
`;
