import {gql} from '@apollo/client';

export const ADMIN_RENOVATION_SCENARIO = gql`
  query adminRenovationScenario($id: ID!) {
    adminRenovationScenario(id: $id) {
      loanPassLink
      lead {
        id
        county
        calculatorToken
        crmLeadId
        crmOpportunityId
        additionalRenovationDetails
        firstName
        lastName
        email
        employmentStatus
        streetAddress
        city
        state
        inLicensedState
        hasNewsletterConsent
        zipCode
        workingLongTerm
        afterRenovatedValue
        homeValue
        estimatedHomeValue
        firstMortgageBalance
        firstMortgageRate
        renovationCost
        loanAmount
        loanAmountDesiredForRenovation
        creditScore
        selfReportedCreditScore
        realCreditScoreNumber
        medal
        monthlyDebt
        monthlyIncomeAlimony
        monthlyIncomeChildSupport
        monthlyIncome
        notMyScoreReportedAt
        phone
        stars
        estimatedDti
        arvCltv
        peakCltv
        prequalStartedAt
        prequalCompletedAt
        prequalConditions
        prequalFailures
        prequalResult
        propertyPurchasePrice
        alimonyPayment
        childSupportPayment
        isLivingInPropertyDuringReno
        loanSubject
        isSelfEmploymentIncomeStable
        stageOfPurchase
        alreadyStartedPropertyStateDetails
        annualIncomePretax
        creditCardPayment
        hasLateMortgagePayments
        primaryMortgagePayment
        secondaryMortgagePayment
        secondMortgageBalance
        totalMortgageBalance
        personalLoanPayment
        propertyStructureType
        propertyUseType
        recentBankruptcy
        hasRenovationEstimate
        renovationIdealStart
        renovationPerformedByType
        propertyOwnershipStatus
        teardownOfExistingStructure
        estimatedDaysUntilClosingPurchase
        demolishExistingStructure
        preExistingStructuresOnLandPresent
        anythingLeftFromOriginalStructure
        studentLoanPayment
        studentLoanBalance
        studentLoanPrePandemicPayment
        isSubcontractingMany
        isLicensedContractor
        subcontractorsSecured
        subcontractorsIdentified
        vehiclePayment
        estimatedProjectSize
        createdAt
        updatedAt
        unsecuredBalance
        unsecuredRatio
        propertyUnitCount
        estimatedDownpayment
        changeHistory {
          createdAt
          field
          value
        }
      }
      lender {
        name
        code
        logoUrl
      }
      loanProductMatches {
        arvCltv
        estimatedDti
        peakCltv
        matchQuality

        calculationsByRate {
          rate
          requestedLoanAmountIncludingRefinance
          maxLoanAmountOffered
          monthlyPaymentForDti
          arvCltv
          estimatedDti
          maxLoanAmountByArvCltv
          maxLoanAmountByDti
          maxLoanAmountByPeakCltv
          monthlyPayment
          peakCltv
        }

        product {
          id
          name
          lender {
            name
            code
          }
          maxLoanAmount
          minCreditScore
          maxPeakCltv
          maxArvCltv
          productType
          priority
          rates
          rateType
          interestOnly
          repaymentPeriod
          maxDti
          isFirstLien
          isSecondLien
        }

        explainMatch {
          name
          problems {
            message
            type
            inputs {
              requestedLoanAmountIncludingRefinance
              maxLoanAmountOffered
              arvCltv
              bestBorrowingPower
              bestIsMatch
              bestMinRate
              bestPriority
              bestProductId
              bestRepaymentPeriod
              betterMatchIds
              county
              creditScore
              estimatedDti
              leadExternalOriginType
              leadSourceLenderCode
              limit
              maxLoanAmountByArvCltv
              maxLoanAmountByDti
              maxLoanAmountByPeakCltv
              peakCltv
              productClass
              productExcludedStates
              productLeadExternalOriginTypes
              productLenderCode
              productMatchable
              productMaxArvCltv
              productMaxCreditScore
              productMaxDti
              productMaxLoanAmount
              productMaxPeakCltv
              productMaxUnitCount
              productMaxUnsecuredDebtRatio
              productMinCreditScore
              productMinDti
              productMinLoanAmount
              productMinUnitCount
              productNational
              productPropertyStructureTypes
              productPropertyUseTypes
              productRaasOnly
              productServiceCounties {
                state
                counties
              }
              productServiceStates
              propertyStructureType
              propertyUnitCount
              propertyUseType
              state
              threshold
              unsecuredDebtRatio
              value
            }
          }
        }
      }
    }
  }
`;
