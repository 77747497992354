export const APPRAISAL_COSTS_BY_STATE = [
  {state: 'Alabama', cost: 581.67},
  {state: 'Alaska', cost: 1275.0},
  {state: 'Arizona', cost: 719.0},
  {state: 'Arkansas', cost: 735.0},
  {state: 'California', cost: 950.87},
  {state: 'Colorado', cost: 962.6},
  {state: 'Connecticut', cost: 782.08},
  {state: 'Delaware', cost: 617.5},
  {state: 'District of Columbia', cost: 772.5},
  {state: 'Florida', cost: 764.06},
  {state: 'Georgia', cost: 662.58},
  {state: 'Hawaii', cost: 1030.0},
  {state: 'Idaho', cost: 790.0},
  {state: 'Illinois', cost: 606.77},
  {state: 'Indiana', cost: 667.69},
  {state: 'Iowa', cost: 636.67},
  {state: 'Kansas', cost: 718.57},
  {state: 'Kentucky', cost: 673.33},
  {state: 'Louisiana', cost: 657.5},
  {state: 'Maine', cost: 860.0},
  {state: 'Maryland', cost: 710.59},
  {state: 'Massachusetts', cost: 793.64},
  {state: 'Michigan', cost: 624.11},
  {state: 'Minnesota', cost: 792.5},
  {state: 'Mississippi', cost: 525.0},
  {state: 'Missouri', cost: 733.89},
  {state: 'Montana', cost: null},
  {state: 'Nebraska', cost: 746.67},
  {state: 'Nevada', cost: 770.25},
  {state: 'New Hampshire', cost: 894.0},
  {state: 'New Jersey', cost: 603.87},
  {state: 'New Mexico', cost: null},
  {state: 'New York', cost: 864.0},
  {state: 'North Carolina', cost: 823.33},
  {state: 'North Dakota', cost: null},
  {state: 'Ohio', cost: 571.0},
  {state: 'Oklahoma', cost: 730.0},
  {state: 'Oregon', cost: 1073.13},
  {state: 'Pennsylvania', cost: 595.69},
  {state: 'Rhode Island', cost: 530.0},
  {state: 'South Carolina', cost: 744.0},
  {state: 'South Dakota', cost: null},
  {state: 'Tennessee', cost: 703.24},
  {state: 'Texas', cost: null},
  {state: 'Utah', cost: 715.42},
  {state: 'Vermont', cost: null},
  {state: 'Virginia', cost: 674.25},
  {state: 'Washington', cost: 1069.72},
  {state: 'West Virginia', cost: null},
  {state: 'Wisconsin', cost: 710.0},
  {state: 'Wyoming', cost: null},
];
