import styled from '@emotion/styled';
import {Link} from 'react-router-dom';

export const BackWrapper = styled(Link)({
  display: 'inline-block',
  width: 24,
  height: 24,
  cursor: 'pointer',
  marginRight: 16,
});
