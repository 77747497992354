import styled from '@emotion/styled/macro';
import {Flex, Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const TickWrapper = styled(Box)(
  mediaQuery({
    position: 'absolute',
    right: 13,
    top: 10,
  }),
  ({open}) => ({
    transform: open ? 'none' : 'rotate(180deg)',
    transition: 'transform .15s',
  }),
);

export const HistoryItemWrapper = styled(Flex)(
  mediaQuery({
    alignItems: 'left',
    position: 'relative',
    cursor: 'default',
  }),
  ({bgColor, css}) => ({
    padding: '10px 10px',
    background: bgColor || 'white',
    ...css,
  }),
);
