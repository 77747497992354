import {makeVar} from '@apollo/client';

// Reactive variables
export const scenarioVar = makeVar({
  loanPassLink: '',
  lead: {status: {}, history: {}},
  lender: {},
  productOptions: [],
  id: null,
});

// Setter
export const setScenario = (scenario) => {
  scenarioVar({...scenarioVar(), ...scenario, id: scenario?.lead.id});
};

// Apollo field policy
export const scenarioPolicy = {
  read() {
    return scenarioVar();
  },
};
