import styled from '@emotion/styled/macro';
import {Box, Flex, Text} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Wrapper = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
  }),
);

export const Section = styled(Box)(
  mediaQuery({
    margin: '10px 25px',
  }),
);

export const Heading = styled(Text)(
  mediaQuery({
    fontWeight: 400,
  }),
  ({mb, mt, mr, ml, m, p, css, small, superSmall, left}) =>
    mediaQuery({
      position: 'relative',
      fontSize: superSmall ? 14 : small ? [18, 18] : [20, 24],
      lineHeight: small || superSmall ? ['20px', '24px'] : ['24px', '32px'],
      margin: ['0 0px 70px 0px', '0 0 40px 0'],
      textAlign: left ? 'left' : 'center',
      ...(mb ? {marginBottom: mb} : {}),
      ...(mt ? {marginTop: mt} : {}),
      ...(mr ? {marginRight: mr} : {}),
      ...(ml ? {marginLeft: ml} : {}),
      ...(m ? {margin: m} : {}),
      ...(p ? {padding: p} : {padding: [0, 0]}),
      ...css,
    }),
);

export const Chip = styled.span(
  mediaQuery({
    paddingRight: '10px',
    paddingLeft: '10px',
    lineHeight: 1.5,
    fontSize: 16,
    borderRight: '2px solid #CCCCCC',
    '&:last-of-type': {
      border: 'none',
    },
  }),
);

export const Footer = styled.div(
  mediaQuery({
    padding: ['20px 15px 70px 15px', 20],
    borderTop: 'solid 1px white',
    background: '#F8F8F8',
    height: '70px',
  }),
);
