import React from 'react';

import {useReactiveVar} from '@apollo/client';
import {isFinite} from 'lodash';

import {toCurrency, toPercent} from '@renofi/utils/src/format';
import {Panel} from '@renofi/components';

import {History, BoolValue, Value} from '../../components';
import {Grid} from '../styled';
import {scenarioVar} from '../../api/cache/scenario';

function Debt() {
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;
  const {monthlyDebt, monthlyIncome} = lead;
  const dti = monthlyDebt / monthlyIncome;

  return (
    <Panel dark background="white" title="Debt details">
      <Grid>
        <Value label="Monthly debt">${toCurrency(lead.monthlyDebt)}</Value>

        <Value label="Estimated current DTI" status={lead.status.estimatedDti}>
          {isFinite(dti) ? toPercent(dti) : '-'}
          <History
            items={lead.history.estimatedDti}
            formatFn={(value) => toPercent(value)}
          />
        </Value>

        <Value
          label="Bankruptcy / foreclosure"
          status={lead.status.recentBankruptcy}>
          <BoolValue value={lead.recentBankruptcy} />
          <History
            items={lead.history.recentBankruptcy}
            formatFn={(value) => <BoolValue small value={value} />}
          />
        </Value>

        <Value
          label="Missed or made any late payments on a home loan?"
          status={lead.status.hasLateMortgagePayments}>
          <BoolValue value={lead.hasLateMortgagePayments} />
          <History
            items={lead.history.hasLateMortgagePayments}
            formatFn={(value) => <BoolValue small value={value} />}
          />
        </Value>

        <Value label="Monthly personal loan payments">
          ${toCurrency(lead.personalLoanPayment)}
        </Value>

        <Value label="Monthly mortgage payments">
          ${toCurrency(lead.primaryMortgagePayment)}
        </Value>

        <Value label="2nd home and/or investment property mortgage">
          ${toCurrency(lead.secondaryMortgagePayment)}
        </Value>

        <Value label="Existing mortgage rate">{lead.firstMortgageRate}%</Value>

        <Value label="Outstanding Home Equity / HELOC">
          ${toCurrency(lead.secondMortgageBalance)}
        </Value>

        <Value label="Vehicle loans/lease payments">
          ${toCurrency(lead.vehiclePayment)}
        </Value>

        <Value label="Student loan monthly payments">
          ${toCurrency(lead.studentLoanPayment)}
        </Value>

        <Value label="Student loan outstanding balance">
          ${toCurrency(lead.studentLoanBalance)}
        </Value>

        <Value label="Credit card payments">
          ${toCurrency(lead.creditCardPayment)}
        </Value>

        <Value label="Monthly alimony payments">
          ${toCurrency(lead.alimonyPayment)}
        </Value>

        <Value label="Monthly child support payments">
          ${toCurrency(lead.childSupportPayment)}
        </Value>
      </Grid>
    </Panel>
  );
}

export default Debt;
