import {redirectToLogin} from '@renofi/utils';

const ENV = process.env.REACT_APP_ENV;

export default () => {
  const redirectBackUrl = `${window.location.origin}`;
  const params = ENV === 'staging' ? '&includeJwt=true' : '';

  redirectToLogin(`?redirectUrl=${redirectBackUrl}${params}`);
};
