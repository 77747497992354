import jwtDecode from './jwtDecode';
import {ONE_MINUTE_MS, ONE_SECOND_MS} from './constants';

function getRandomInt(max, zeroIndex = true) {
  return Math.floor(Math.random() * max) + (zeroIndex ? 0 : 1);
}

export default (jwt, {clamp = true, wobble = true} = {}) => {
  const decodedJwt = jwtDecode(jwt);
  const randomWobble = ONE_SECOND_MS * getRandomInt(10, false);
  const refreshAt = (decodedJwt?.exp || 0) * ONE_SECOND_MS - ONE_MINUTE_MS;

  const diff = refreshAt - Date.now();
  const finalValue = clamp ? Math.max(0, diff) : diff;

  return wobble ? finalValue + randomWobble : finalValue;
};
