import React from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';
import {Text, Flex} from 'rebass';

import {toCurrency} from '@renofi/utils/src/format';
import {StatusBadge, Button} from '@renofi/components';

import {scenarioVar} from '../../../api/cache/scenario';

import {NameWrapper} from './styled';

function SummaryTitle({onLoanProductClick}) {
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;

  return (
    <Flex justifyContent="space-between">
      <NameWrapper>
        <Text mr={30}>
          {lead.firstName} {lead.lastName}
        </Text>
        <StatusBadge mr={30} text={lead.prequalResult} />
        <Text>Seeking ${toCurrency(lead.loanAmountDesiredForRenovation)}</Text>
      </NameWrapper>
      <Button xSmall secondary onClick={onLoanProductClick}>
        Loan Product Options
      </Button>
    </Flex>
  );
}

SummaryTitle.propTypes = {
  onLoanProductClick: PropTypes.func.isRequired,
};

export default SummaryTitle;
