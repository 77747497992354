import React from 'react';

import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {gray} from '@renofi/utils/src/colors';

const SearchOptionValue = ({text, icon, children}) => {
  const Icon = icon;
  return (
    <Flex alignItems="center" pt="5px">
      {text || children ? <Icon /> : null}
      <Text pl="5px" color={gray} css={{fontWeight: 300}}>
        {text || children}
      </Text>
    </Flex>
  );
};

SearchOptionValue.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.object,
  children: PropTypes.node,
};

export default SearchOptionValue;
