import {jwtDecode} from 'jwt-decode';

import {logger} from '../index';

export default (jwt) => {
  let decoded = null;
  if (!jwt) {
    return decoded;
  }

  try {
    decoded = jwtDecode(jwt);
  } catch (err) {
    logger.error('[renofi] invalid JWT token given of:', jwt);
    logger.error(err);
  }
  return decoded;
};
