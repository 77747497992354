import React from 'react';

import PropTypes from 'prop-types';
import {isEmpty, startCase} from 'lodash';
import {Text, Flex} from 'rebass';

import {InfoTooltip, PanelDark} from '@renofi/components';
import {gray} from '@renofi/utils/src/colors';

import MatchProblemsIcon from './MatchProblemsIcon';
import {getInputsKeys, getFormattedValue} from './utils';
import {ICON_COLOR_MAPPER} from './constants';

const MatchProblemsTooltip = ({explainMatch, index, matchQuality}) => {
  const id = index.toString();
  const iconColor = ICON_COLOR_MAPPER[matchQuality] || gray;

  return (
    <InfoTooltip
      id={id}
      place="left"
      width={800}
      customIcon={<MatchProblemsIcon color={iconColor} />}
      css={{
        padding: '0 10px',
        position: 'static',
        cursor: 'pointer',
      }}>
      <PanelDark title="Match Problems">
        <Flex flexDirection="column" css={{gap: 30}}>
          {explainMatch.map(({name, problems}) => {
            return (
              <Flex flexDirection="column" css={{gap: 30}} key={name}>
                {problems.map(({message, type, inputs}) => {
                  const inputsKeys = getInputsKeys(inputs);

                  return (
                    <Flex flexDirection="column" key={message}>
                      <Text fontSize={16}>Message: {message}</Text>
                      <Text fontSize={16}>Type: {type}</Text>

                      {!isEmpty(inputsKeys) ? (
                        <Flex
                          flexWrap="wrap"
                          flexDirection="row"
                          css={{gap: '0 10px'}}>
                          {inputsKeys.map((key) => {
                            const humanizedKey = startCase(key);
                            const value = inputs[key];

                            const formattedValue = getFormattedValue(value);

                            return (
                              <Text
                                fontSize={16}
                                pr="10px"
                                key={key}
                                align="left"
                                css={{
                                  ':not(:last-child)': {
                                    borderRight: '1px solid gray',
                                  },
                                }}>
                                {humanizedKey}: {formattedValue}
                              </Text>
                            );
                          })}
                        </Flex>
                      ) : null}
                    </Flex>
                  );
                })}
              </Flex>
            );
          })}
        </Flex>
      </PanelDark>
    </InfoTooltip>
  );
};

MatchProblemsTooltip.propTypes = {
  matchQuality: PropTypes.string,
  index: PropTypes.number,
  explainMatch: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      problems: PropTypes.arrayOf(
        PropTypes.shape({
          inputs: PropTypes.shape({
            requestedLoanAmountIncludingRefinance: PropTypes.number,
            estimatedDti: PropTypes.number,
            maxLoanAmountByArvCltv: PropTypes.number,
            maxLoanAmountByDti: PropTypes.number,
            maxLoanAmountByPeakCltv: PropTypes.number,
            productMaxDti: PropTypes.number,
            productMaxLoanAmount: PropTypes.number,
            productPropertyStructureTypes: PropTypes.arrayOf(PropTypes.string),
            threshold: PropTypes.number,
          }),
          message: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default MatchProblemsTooltip;
