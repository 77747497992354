import React, {useEffect} from 'react';

import {Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@emotion/react';

import {breakpoint} from '@renofi/utils/src/mediaQuery';
import {initGooglePlaces} from '@renofi/utils/src/places';

import {SearchPage} from '../Search';
import Admin from '../Admin';
import Products from '../Products';
import EstimatePage from '../Estimate';

const theme = {
  breakpoints: [`${breakpoint}px`],
};

function App() {
  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    if (apiKey) initGooglePlaces(apiKey);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/products">
          <Products />
        </Route>
        <Route path="/estimate">
          <EstimatePage />
        </Route>
        <Route exact path="/:id/estimate">
          <EstimatePage />
        </Route>
        <Route exact path="/search">
          <SearchPage />
        </Route>
        <Route exact path="/:id">
          <Admin />
        </Route>
        <Route path="/:id/:name?">
          <Admin />
        </Route>
        <Redirect to="/search" />
      </Switch>
    </ThemeProvider>
  );
}

export default App;
