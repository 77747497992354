import {APPRAISAL_COSTS_BY_STATE} from './appraisalCostPerState';

// 1stMortgagePayment + 2ndMortgagePayment + hoaInsurance + floodInsurance + hazardInsurance + realEstateTaxes = estimatedMonthlyTotalPayment
export const calcEstimatedMonthlyTotalPayment = (newData) =>
  (Number(newData.firstMortgagePayment) || 0) +
    (Number(newData.secondMortgagePayment) || 0) +
    (Number(newData.hoaInsurance) || 0) +
    (Number(newData.floodInsurance) || 0) +
    (Number(newData.hazardInsurance) || 0) +
    (Number(newData.realEstateTaxes) || 0) || '';

// originationFee + titleFee + recordingAndTax = estimatedClosingCosts
export const calcEstimatedClosingCosts = (newData) =>
  (Number(newData.originationFee) || 0) +
    (Number(newData.titleFee) || 0) +
    (Number(newData.recordingAndTax) || 0) || '';

// totalLoanAmount - payOffAmount ?? - estimatedTotalClosingCost = netEstimatedFunds
export const calcNetEstimatedFunds = ({
  loanAmountDesiredForRenovation,
  payOffAmount,
  estimatedClosingCosts,
}) => loanAmountDesiredForRenovation - payOffAmount - estimatedClosingCosts;

export const calcEstimatedInterestRateHeloc = (newData) => {
  const result =
    (Number(newData.margin) || 0) + (Number(newData.primeIndexStartRate) || 0);
  return parseFloat(result.toFixed(3)) || '';
};

export const limitToThreeDp = (value) => {
  const result = Number(value);
  return parseFloat(result.toFixed(3)) || '';
};

export const mapProductType = (type) =>
  type?.includes('heloc')
    ? 'RenoFi Home Equity Line of Credit'
    : 'RenoFi Home Equity';

export const isRefi = (type) => type && type.includes('refi');

export const isPersonalLoan = (type) => type && type.includes('personal');

/// Note - we only have Quorum and USAlliance origination fees at the moment
export const getOriginationFee = (code) => {
  switch (code) {
    case 'us_alliance':
      return 199;
    case 'quorum':
      return 595;
    default:
      return null;
  }
};

export const getAppraisalCosts = (state) => {
  const result = APPRAISAL_COSTS_BY_STATE.find(
    (appraisalCost) => appraisalCost?.state === state,
  );
  return result?.cost;
};
