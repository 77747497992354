import {useQuery} from '@apollo/client';

import {CURRENT_USER} from '../queries/currentUser';

export default function useCurrentUser() {
  const {data, loading} = useQuery(CURRENT_USER, {fetchPolicy: 'cache-first'});
  const {email, firstName, lastName, phoneNumber} = data?.user || {};
  const isMissingSalesForceFields = !(
    email &&
    firstName &&
    lastName &&
    phoneNumber
  );
  return {isMissingSalesForceFields, currentUser: data?.user, loading};
}
