import React from 'react';

import {
  Switch,
  useRouteMatch,
  useHistory,
  useParams,
  Route,
} from 'react-router-dom';

import {Modal} from '@renofi/components';

import SuccessAlert from '../PreApplication/SuccessAlert';
import PreApplication from '../PreApplication';
import LoanOptions from '../LoanOptions';

import BackArrow from './BackArrow';

const LoanOptionsModal = () => {
  const {path, url} = useRouteMatch();
  const history = useHistory();
  const {id} = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <Modal
          width="calc(100% - 30px)"
          show={true}
          onClose={() => history.push(`/${id}`)}
          title="Loan Product Options"
          contentCss={{padding: 0}}>
          <LoanOptions />
        </Modal>
      </Route>
      <Route path={`${path}/:id/success`}>
        <Modal
          width="auto"
          show={true}
          onClose={() => history.push(`/${id}`)}
          title="Pre-Application Worksheet"
          contentCss={{padding: 0}}
          light>
          <SuccessAlert close={() => history.push(`/${id}`)} />
        </Modal>
      </Route>
      <Route path={`${path}/:id`}>
        <Modal
          width="auto"
          show={true}
          onClose={() => history.push(`/${id}`)}
          title="Pre-Application Worksheet"
          backButton={<BackArrow to={url} />}
          contentCss={{padding: 0}}>
          <PreApplication />
        </Modal>
      </Route>
    </Switch>
  );
};

export default LoanOptionsModal;
