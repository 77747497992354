import {useMutation} from '@apollo/client';

import {REQUEST_WORKSHEET_HOME_EQUITY_LINE_OF_CREDIT} from '../mutations/requestWorksheetHomeEquityLineOfCredit';

export default function UseRequestWorksheetHomeEquityLineOfCredit() {
  const [requestWorksheetHomeEquityLineOfCredit, {data, loading, error}] =
    useMutation(REQUEST_WORKSHEET_HOME_EQUITY_LINE_OF_CREDIT);

  const response = data?.requestWorksheetHomeEquityLineOfCredit;
  return {requestWorksheetHomeEquityLineOfCredit, response, loading, error};
}
