import styled from '@emotion/styled/macro';
import {Box, Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';
import {darkGray} from '@renofi/utils/src/colors';

import {Label} from '../../../components';

export const DetailsItem = styled(Box)(
  mediaQuery({
    marginRight: 60,
  }),
);

export const DetailsWrapper = styled(Flex)(
  mediaQuery({
    flexDirection: ['column', 'row'],
    background: '#F8F8F8',
    marginRight: 40,
    padding: ['20px 25px 0 25px', '20px 35px 15px 35px'],
    borderBottom: 'solid 1px #DEDEDE',
    margin: '-15px -15px 0 -15px',
  }),
);

export const DetailsValue = styled(Label)(
  mediaQuery({
    color: darkGray,
    marginTop: 5,
    marginBottom: [20, 0],
  }),
);
