import {gql} from '@apollo/client';

export const CURRENT_USER = gql`
  query user {
    user {
      id
      email
      firstName
      lastName
      phoneNumber
    }
  }
`;
