import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {Box} from 'rebass';
import {useLazyQuery} from '@apollo/client';

import {toPercent} from '@renofi/utils/src/format';
import {Table, Th, Td} from '@renofi/components';

import {PRODUCTS} from '../api';
import {CurrencyValue} from '../components';
import Header from '../Header';

export default function Products() {
  const [query, {loading, data}] = useLazyQuery(PRODUCTS);

  useEffect(() => {
    (async () => {
      await query();
    })();
  }, []);

  return (
    <Box
      width={['100%', 'calc(100% - 60px)']}
      maxWidth={1320}
      mx="auto"
      px={[15, 0]}
      pt={30}>
      <Header />
      <br />
      <Table>
        <thead>
          <tr>
            <Th>Lender</Th>
            <Th>Product</Th>
            <Th>Terms</Th>
            <Th>APRs</Th>
            <Th>Estimated Closing Costs</Th>
            <Th>Max Peak</Th>
            <Th>Max ARV</Th>
            <Th>Loan Size</Th>
            <Th>Priority</Th>
            <Th>Match Status</Th>
          </tr>
        </thead>
        <tbody>
          {!loading &&
            data &&
            data.products.map((product) => (
              <ProductRow product={product} key={product.id} />
            ))}
        </tbody>
      </Table>
    </Box>
  );
}

function ProductRow({product}) {
  return (
    <tr key={product.id}>
      <Td>{product.lender.name}</Td>
      <Td>{product.name}</Td>
      <Td>
        {product.repaymentPeriod} years {product.rateType}
      </Td>
      <Td>{product.rates.join(' / ')}</Td>
      <Td>
        <CurrencyValue value={product.minEstimatedClosingCost} /> -{' '}
        <CurrencyValue value={product.maxEstimatedClosingCost} />
      </Td>
      <Td>{toPercent(product.maxPeakCltv)}</Td>
      <Td>{toPercent(product.maxArvCltv)}</Td>
      <Td>
        <CurrencyValue value={product.minLoanAmount} /> -{' '}
        <CurrencyValue value={product.maxLoanAmount} />
      </Td>
      <Td>{product.priority}</Td>
      <Td>{product.matchable ? 'Match' : 'Not a match'}</Td>
    </tr>
  );
}

ProductRow.propTypes = {
  product: PropTypes.object,
};
