import {useQuery} from '@apollo/client';

import {ADMIN_RENOVATION_SCENARIO} from '../queries/adminRenovationScenario';
import {onScenarioUpdate} from '../callbacks';

export default function useRenovationScenario(options, isMock) {
  const {refetch} = useQuery(ADMIN_RENOVATION_SCENARIO, {
    ...options,
    skip: true,
    onCompleted: ({adminRenovationScenario}) => {
      if (!isMock) onScenarioUpdate(adminRenovationScenario);
    },
  });
  const imperativelyCallQuery = (variables) => {
    return refetch(variables);
  };
  return imperativelyCallQuery;
}
