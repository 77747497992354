import React from 'react';

import PropTypes from 'prop-types';

import {toCurrency} from '@renofi/utils/src/format';
import {Toggle} from '@renofi/components';

function CurrencyValue({value}) {
  if (value === 0) return '$0';
  return <Toggle show={!!value}>${toCurrency(value)}</Toggle>;
}

CurrencyValue.propTypes = {
  value: PropTypes.number,
};

export default CurrencyValue;
