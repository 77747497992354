import {sendEvent} from '@renofi/analytics';
import {AUTHENTICATE_BY_LOGIN_TOKEN, GET_CURRENT_USER} from '@renofi/api';
import {logger} from '@renofi/utils';

import setCookieByDomain from './setCookieByDomain';

export default async ({
  analyticsPrefix,
  client,
  onAuthError,
  onAuthSuccess,
  token,
}) => {
  try {
    const rsp = await client.query({
      query: AUTHENTICATE_BY_LOGIN_TOKEN,
      fetchPolicy: 'no-cache',
      variables: {token},
    });

    const {jwt, refreshToken, user} = rsp?.data?.authenticateByLoginToken || {};
    if (!jwt || !refreshToken) {
      logger.error('No JWT found');
      sendEvent(`${analyticsPrefix}/No-JWT-Returned`);
      return onAuthError();
    }

    setCookieByDomain('jwt', jwt);
    setCookieByDomain('refreshToken', refreshToken);
    if (analyticsPrefix) {
      sendEvent(`${analyticsPrefix}/Authenticated`);
    }

    // token login returns a User, so we can write directly into cache.
    await client.writeQuery({
      query: GET_CURRENT_USER,
      data: {
        result: user,
      },
    });
    await onAuthSuccess();
    sendEvent(`${analyticsPrefix}/Authenticated-By-Token`);
  } catch (err) {
    logger.error(err);
    sendEvent(`${analyticsPrefix}/Authenticate-By-Token-Error`, {err});
    onAuthError();
  }
};
