import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const NameWrapper = styled(Box)(
  mediaQuery({
    alignItems: 'center',
    display: 'inline-flex',
  }),
);
