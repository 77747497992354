import {getItem, deleteCookie} from '../cookies';
import logger from '../logger';

import getRefreshTokenInterval from './getRefreshTokenInterval';

const PARAMS = {wobble: false, clamp: false};

export default function areAuthTokensExpired(dispatch) {
  const jwt = getItem('jwt');
  const refreshToken = getItem('refreshToken');
  if (!jwt || !refreshToken) {
    return true;
  }
  const jwtExp = getRefreshTokenInterval(jwt, PARAMS);
  const refreshTokenExp = getRefreshTokenInterval(refreshToken, PARAMS);
  const isExpired = jwtExp < 0 || refreshTokenExp < 0;

  if (isExpired) {
    logger.debug(`💀 JWT/refreshToken is expired`);

    deleteCookie('jwt');
    deleteCookie('refreshToken');

    if (Boolean(dispatch)) {
      dispatch({
        type: 'error',
        message: 'Your session has expired. Please login again.',
      });
    }
  }
  return isExpired;
}
