import {gql} from '@apollo/client';

export const REQUEST_WORKSHEET_HOME_EQUITY_LINE_OF_CREDIT = gql`
  mutation requestWorksheetHomeEquityLineOfCredit(
    $scenarioId: ID!
    $homeownerName: String
    $propertyZipCode: String
    $email: String!
    $lenderName: String
    $estimatedAfterRenovationValue: Int
    $loanAmount: Int
    $loanProductId: ID!
    $rateType: String
    $intrestRate: Float
    $term: String
    $appraisal: Appraisal
    $closingCosts: ClosingCosts
    $estimatedMonthlyPayment: EstimatedMonthlyPayment
    $totalEstFundsBorrower: TotalEstFundsBorrower!
  ) {
    requestWorksheetHomeEquityLineOfCredit(
      scenarioId: $scenarioId
      homeownerName: $homeownerName
      propertyZipCode: $propertyZipCode
      email: $email
      lenderName: $lenderName
      estimatedAfterRenovationValue: $estimatedAfterRenovationValue
      loanAmount: $loanAmount
      loanProductId: $loanProductId
      rateType: $rateType
      intrestRate: $intrestRate
      term: $term
      appraisal: $appraisal
      closingCosts: $closingCosts
      estimatedMonthlyPayment: $estimatedMonthlyPayment
      totalEstFundsBorrower: $totalEstFundsBorrower
    ) {
      success
    }
  }
`;
