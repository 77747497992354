import {useContext} from 'react';

import * as lodash from 'lodash';
import * as uuid from 'uuid';

import {NotificationContext} from './NotificationsProvider';

const DELAY = 4000;

export function useNotifications() {
  const dispatch = useContext(NotificationContext) || lodash.noop;

  const removeNotification = (id) => {
    dispatch({
      type: 'REMOVE_NOTIFICATION',
      id,
    });
  };

  return (props) => {
    const id = uuid.v4();

    setTimeout(() => {
      removeNotification(id);
    }, DELAY);

    dispatch({
      type: 'ADD_NOTIFICATION',
      payload: {
        id,
        ...props,
      },
    });
  };
}
