import {get, head, last} from 'lodash';

export function recalculateLoanProductMatches({lead, loanProductMatches}) {
  const {loanAmountDesiredForRenovation} = lead;

  return (
    loanProductMatches.map(({product, ...rest}) => {
      const cashoutAmount = loanAmountDesiredForRenovation;
      const loanAmountNeeded = calculateLoanAmountNeeded(lead, product);

      return {
        ...product,
        cashoutAmount,
        loanAmountNeeded,
        ...calculateEstimatedPayments(
          loanAmountDesiredForRenovation,
          rest.calculationsByRate,
        ),
        ...rest,
      };
    }) || []
  );
}

function calculateLoanAmountNeeded(lead, {isFirstLien, isSecondLien}) {
  const {loanAmountDesiredForRenovation} = lead;
  const secondMortgageBalance = get(lead, 'secondMortgageBalance', 0);
  const firstMortgageBalance = get(lead, 'firstMortgageBalance', 0);

  if (isFirstLien) {
    return (
      loanAmountDesiredForRenovation +
      firstMortgageBalance +
      secondMortgageBalance
    );
  }

  if (isSecondLien) {
    return loanAmountDesiredForRenovation + secondMortgageBalance;
  }

  return loanAmountDesiredForRenovation;
}

//TODO: is this ever used?
function calculateEstimatedPayments(
  loanAmountDesiredForRenovation = 0,
  calculationsByRate = [],
) {
  if (calculationsByRate.length < 1) return {};

  const firstCalculationByRate = head(calculationsByRate);
  const lastCalculationByRate = last(calculationsByRate);
  const firstPayment = firstCalculationByRate.monthlyPaymentForDti;
  const lastPayment = lastCalculationByRate.monthlyPaymentForDti;

  const firstCalculatedMaxCashoutAmount =
    firstCalculationByRate.maxLoanAmountOffered;
  const lastCalculatedMaxCashoutAmount =
    lastCalculationByRate.maxLoanAmountOffered;

  const maxCashoutAmount = Math.max(
    firstCalculatedMaxCashoutAmount,
    lastCalculatedMaxCashoutAmount,
  );
  const diffCashoutAmount =
    firstCalculatedMaxCashoutAmount !== lastCalculatedMaxCashoutAmount;
  const borrowingPercentDiff =
    1 - maxCashoutAmount / loanAmountDesiredForRenovation;
  const canBeBorrowed = loanAmountDesiredForRenovation <= maxCashoutAmount;

  return {
    firstCalculatedMaxCashoutAmount,
    lastCalculatedMaxCashoutAmount,
    maxCashoutAmount,
    diffCashoutAmount,
    borrowingPercentDiff,
    canBeBorrowed,
    firstPayment,
    lastPayment,
  };
}
