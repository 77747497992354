import {constants} from '@renofi/utils';

import {STAGE_OF_PURCHASE} from './const';

export const getLoanSubject = (lead) => {
  const {LOAN_SUBJECT} = constants;

  switch (lead.loanSubject) {
    case LOAN_SUBJECT.HOME_PURCHASED:
      return `Purchasing - ${STAGE_OF_PURCHASE[lead.stageOfPurchase]}`;
    case LOAN_SUBJECT.CURRENT_HOME:
      return 'Current home';
    case LOAN_SUBJECT.ADU:
      return `ADU - ${STAGE_OF_PURCHASE[lead.stageOfPurchase]}`;
    default:
      return '-';
  }
};
