import React from 'react';

import PropTypes from 'prop-types';

import {ReactComponent as ArrowIcon} from './arrow.svg';
import {BackWrapper} from './BackArrow.styled';

const BackArrow = ({to}) => {
  return (
    <BackWrapper to={to}>
      <ArrowIcon />
    </BackWrapper>
  );
};

BackArrow.propTypes = {
  to: PropTypes.string,
};

export default BackArrow;
