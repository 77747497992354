import React, {useEffect, useRef} from 'react';

import {Box, Flex} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {ReactComponent as LogoIcon} from '../App/img/logo.svg';

import {ReactComponent as SearchIcon} from './img/search.svg';
import Search from './Search';

function SearchPage() {
  const searchRef = useRef();
  const {isDesktop} = useScreenSize();

  useEffect(() => {
    setTimeout(() => searchRef?.current?.focus(), 100);
  });

  useEffect(() => {
    sendEvent('Advisors/Search-Page');
  }, []);

  return (
    <Box
      width={['100%', 'calc(100% - 60px)']}
      maxWidth={1160}
      mx="auto"
      px={[15, 0]}>
      <Flex width={[160, 200]} justifyContent="center" my={[20, 70]} mx="auto">
        <LogoIcon />
      </Flex>
      <Toggle show={isDesktop}>
        <Flex width={[280, 'auto']} justifyContent="center" mt={70} pl={60}>
          <SearchIcon />
        </Flex>
      </Toggle>
      <Flex justifyContent="center" mt={[-20, 20]} pb={15}>
        <Box width="100%">
          <h1 align="center">Self Pre-Qualification Result Search</h1>
          <Search />
        </Box>
      </Flex>
    </Box>
  );
}

export default SearchPage;
