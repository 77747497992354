import React from 'react';

import {useReactiveVar} from '@apollo/client';

import {Panel} from '@renofi/components';
import {toCurrency} from '@renofi/utils/src/format';

import {Value} from '../../components';
import {Grid} from '../styled';
import {scenarioVar} from '../../api/cache/scenario';

function Income() {
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;

  return (
    <Panel dark background="white" title="Income details">
      <Grid mb={20}>
        <Value label="Annual combined pre-tax">
          ${toCurrency(lead.annualIncomePretax)}
        </Value>

        <Value label="Monthly income">${toCurrency(lead.monthlyIncome)}</Value>

        <Value label="Monthly Child support received">
          ${toCurrency(lead.monthlyIncomeChildSupport)}
        </Value>

        <Value label="Monthly alimony received">
          ${toCurrency(lead.monthlyIncomeAlimony)}
        </Value>
      </Grid>
    </Panel>
  );
}

export default Income;
