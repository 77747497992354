import {isEqual, intersectionWith} from 'lodash';

import {snakeToCamel} from './format';

const objectMap = (obj, fn) =>
  Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export function createStatusesObject(object) {
  return objectMap(object, (value, key) => {
    return getStatus([key], object);
  });
}

export function createHistoryObject(array) {
  return array.reduce(function (previousValue, currentValue) {
    const key = snakeToCamel(currentValue.field);
    const value = previousValue[key]
      ? [...previousValue[key], currentValue]
      : [currentValue];

    return {...previousValue, [key]: value};
  }, {});
}

function getStatus(keys, lead) {
  if (typeof keys === 'string') {
    keys = [keys];
  }
  const failures = lead?.prequalFailures?.map((key) => snakeToCamel(key));
  const conditions = lead?.prequalConditions?.map((key) => snakeToCamel(key));

  if (intersectionWith(keys, failures, isEqual).length > 0) return FAILURE;
  if (intersectionWith(keys, conditions, isEqual).length > 0) return WARNING;

  return SUCCESS;
}

export function getPropertyStatus(lead) {
  const keys = [
    'propertyStructureType',
    'propertyUseType',
    'loanAmount',
    'state',
  ];
  return getStatus(keys, lead);
}

export function getPersonalStatus(lead) {
  return getStatus(['creditScore'], lead);
}

export function getIncomeStatus(lead) {
  const keys = ['annualIncomePretax'];
  return getStatus(keys, lead);
}

export function getDebtStatus(lead) {
  const keys = [
    'personalLoanPayment',
    'vehiclePayment',
    'primaryMortgagePayment',
    'secondMortgageBalance',
    'secondaryMortgagePayment',
    'firstMortgageRate',
    'primaryMortgagePayment',
    'hasLateMortgagePayments',
    'studentLoanPayment',
    'studentLoanPrePandemicPayment',
    'studentLoanBalance',
    'creditCardPayment',
    'alimonyPayment',
    'childSupportPayment',
    'estimatedDti',
    'recentBankruptcy',
  ];
  return getStatus(keys, lead);
}

export function getRenovationStatus(lead) {
  const keys = [
    'renovationIdealStart',
    'renovationPerformedByType',
    'isSubcontractingMany',
    'additionalRenovationDetails',
    'hasRenovationEstimate',
    'isLicensedContractor',
    'subcontractorsIdentified',
    'subcontractorsSecured',
  ];
  return getStatus(keys, lead);
}

export function combineIncomeAndDebtStatus(lead) {
  const incomeStatus = getIncomeStatus(lead);
  const debtStatus = getDebtStatus(lead);
  if ([incomeStatus, debtStatus].includes(FAILURE)) return FAILURE;
  if ([incomeStatus, debtStatus].includes(WARNING)) return WARNING;
  return SUCCESS;
}

export const SUCCESS = 'PASS';
export const FAILURE = 'FAIL';
export const WARNING = 'CONDITION';
