import React from 'react';

import {Box, Flex} from 'rebass';

import {Toggle} from '@renofi/components';
import {useScreenSize} from '@renofi/utils';

import {ReactComponent as LogoIcon} from '../App/img/logo.svg';
import Search from '../Search';

function Header() {
  const {isDesktop} = useScreenSize();

  return (
    <Flex>
      <Toggle show={isDesktop}>
        <LogoIcon width={120} />
      </Toggle>
      <Box width="100%" ml={[0, 50]}>
        <Search floating />
      </Box>
    </Flex>
  );
}

export default Header;
