/* eslint-disable max-lines */
import React from 'react';

import {Box, Flex} from 'rebass';
import PropTypes from 'prop-types';

import {toCurrency, toPercent} from '@renofi/utils/src/format';
import {blue, fadeGray, gray, lightGray} from '@renofi/utils/src/colors';
import {
  Button,
  CurrencyField,
  Label,
  SelectField,
  TextField,
} from '@renofi/components';

import {mapProductType} from '../../utils/calcPawFields';

import {Chip, Footer, Heading, Section, Wrapper} from './PreApplication.styled';

const PreApplicationForm = ({
  onChange,
  formData,
  calcValues,
  product,
  lead,
  isHeloc,
  onSubmit,
  onCancel,
  calcFields,
  isFormValid,
  isLoading,
}) => {
  const {
    length,
    firstRate,
    lastRate,
    firstCalculatedMaxLoanAmount,
    lastCalculatedMaxLoanAmount,
    maxLoanAmount,
    diffLoanAmount,
    canBeBorrowed,
    arvCltv,
  } = calcValues;

  return (
    <>
      <Wrapper>
        <Section>
          <Heading center mb={[24, 15]} mt={[24, 30]}>
            {mapProductType(product?.productType)}
          </Heading>

          <Flex
            mb={18}
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Box width={[1, 1]} textAlign="center">
              <Chip>{product?.lender?.name}</Chip>
              <Chip>
                Example APR:{' '}
                {length > 1 ? `${firstRate}%-${lastRate}%` : `${firstRate}%`}
              </Chip>
            </Box>
            <Box width={[1, 1]} textAlign="center">
              <Chip>Rate type: {product?.rateType}</Chip>
              <Chip>
                Max borrowing power:{' '}
                {length > 1 && diffLoanAmount
                  ? `$${toCurrency(lastCalculatedMaxLoanAmount)}-$${toCurrency(
                      firstCalculatedMaxLoanAmount,
                    )}`
                  : `$${toCurrency(maxLoanAmount)}`}{' '}
                {!canBeBorrowed &&
                  `${
                    length > 1 && diffLoanAmount
                      ? `(-$${toCurrency(
                          lead.loanAmountDesiredForRenovation -
                            lastCalculatedMaxLoanAmount,
                        )}) (-$${toCurrency(
                          lead.loanAmountDesiredForRenovation -
                            firstCalculatedMaxLoanAmount,
                        )})`
                      : `(-$${toCurrency(
                          lead.loanAmountDesiredForRenovation - maxLoanAmount,
                        )})`
                  }`}
              </Chip>
            </Box>
            <Box width={[1, 1]} textAlign="center">
              <Chip>
                ARV CLTV: {toPercent(arvCltv)}{' '}
                {arvCltv > product?.maxArvCltv &&
                  `(${toPercent(product?.maxArvCltv)})`}{' '}
              </Chip>
              <Chip>
                Est. ARV: {`$${toCurrency(lead.afterRenovatedValue)}`}
              </Chip>
            </Box>
          </Flex>
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Rate
          </Heading>
          {isHeloc && (
            <>
              <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
                <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
                  <Label small htmlFor="primeIndexStartRate">
                    (Prime) Index start rate
                  </Label>
                  <TextField
                    icon="percentage"
                    value={formData.primeIndexStartRate}
                    onChange={(value, err) => {
                      onChange('primeIndexStartRate', value, err);
                    }}
                    onWheel={(e) => e.target.blur()}
                    id="primeIndexStartRate"
                    name="primeIndexStartRate"
                    type="number"
                    inputmode="numeric"
                    pattern={'[0-9]*'}
                    required
                  />
                </Box>
              </Flex>

              <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
                <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
                  <Label small htmlFor="margin">
                    Margin
                  </Label>
                  <TextField
                    icon="percentage"
                    value={formData.margin}
                    onChange={(value, err) => {
                      onChange('margin', value, err);
                    }}
                    onWheel={(e) => e.target.blur()}
                    id="margin"
                    name="margin"
                    type="number"
                    inputmode="numeric"
                    pattern={'[0-9]*'}
                    required
                  />
                </Box>
              </Flex>

              <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
                <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
                  <Label small htmlFor="estimatedInterestRateHeloc">
                    Estimated interest Rate as low as
                  </Label>
                  <TextField
                    icon="percentage"
                    min={0}
                    limit={9}
                    value={calcFields.estimatedInterestRateHeloc}
                    id="estimatedInterestRateHeloc"
                    name="estimatedInterestRateHeloc"
                    type="number"
                    inputmode="numeric"
                    pattern={'[0-9]*'}
                    required
                    disabled={true}
                  />
                </Box>
              </Flex>
            </>
          )}

          {!isHeloc && (
            <>
              <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
                <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
                  <Label small htmlFor="estimatedInterestRate">
                    Estimated interest Rate as low as
                  </Label>
                  <TextField
                    icon="percentage"
                    value={formData.estimatedInterestRate}
                    onChange={(value, err) =>
                      onChange('estimatedInterestRate', value, err)
                    }
                    onWheel={(e) => e.target.blur()}
                    id="estimatedInterestRate"
                    name="estimatedInterestRate"
                    type="number"
                    inputmode="numeric"
                    pattern={'[0-9]*'}
                    required
                  />
                </Box>
              </Flex>
              <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
                <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
                  <Label small htmlFor="term">
                    Term
                  </Label>
                  <SelectField
                    autoComplete="false"
                    value={formData.term}
                    onChange={(value) => onChange('term', value)}
                    disabled
                    id="term"
                    name="term">
                    <option
                      key={product?.id}
                      value={product?.id}
                      name={product?.name}>
                      {`${product?.repaymentPeriod} years ${product?.rateType}`}
                    </option>
                  </SelectField>
                </Box>
              </Flex>
            </>
          )}
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Estimated Monthly Total Payment
          </Heading>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="firstMortgagePayment">
                1st Mortgage Payment
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.firstMortgagePayment}
                onChange={(value, err) =>
                  onChange('firstMortgagePayment', value, err)
                }
                id="firstMortgagePayment"
                name="firstMortgagePayment"
                required
              />
            </Box>
            <Box width={[1, 1 / 2]} pl={[0, 3]}>
              <Label small htmlFor="secondMortgagePayment">
                2nd Mortgage Payment
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.secondMortgagePayment}
                onChange={(value, err) =>
                  onChange('secondMortgagePayment', value, err)
                }
                id="secondMortgagePayment"
                name="secondMortgagePayment"
                required
              />
            </Box>
          </Flex>

          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="hoaInsurance">
                HOA Insurance
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.hoaInsurance}
                onChange={(value, err) => onChange('hoaInsurance', value, err)}
                id="hoaInsurance"
                name="hoaInsurance"
                topMessage="Optional"
              />
            </Box>
            <Box width={[1, 1 / 2]} pl={[0, 3]}>
              <Label small htmlFor="floodInsurance">
                Flood Insurance
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.floodInsurance}
                onChange={(value, err) =>
                  onChange('floodInsurance', value, err)
                }
                id="floodInsurance"
                name="floodInsurance"
                topMessage="Optional"
              />
            </Box>
          </Flex>

          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1 / 2]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="hazardInsurance">
                Hazard Insurance
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.hazardInsurance}
                onChange={(value, err) =>
                  onChange('hazardInsurance', value, err)
                }
                id="hazardInsurance"
                name="hazardInsurance"
                required
              />
            </Box>
            <Box width={[1, 1 / 2]} pl={[0, 3]}>
              <Label small htmlFor="realEstateTaxes">
                Real Estate Taxes
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.realEstateTaxes}
                onChange={(value, err) =>
                  onChange('realEstateTaxes', value, err)
                }
                id="realEstateTaxes"
                name="realEstateTaxes"
                required
              />
            </Box>
          </Flex>

          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="estimatedMonthlyTotalPayment">
                Estimated monthly total payment
              </Label>
              <CurrencyField
                min={0}
                limit={9}
                noSpecialCharacters
                value={calcFields.estimatedMonthlyTotalPayment}
                id="estimatedMonthlyTotalPayment"
                name="estimatedMonthlyTotalPayment"
                required
                disabled
              />
            </Box>
          </Flex>
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Appraisal
          </Heading>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="appraisalCosts">
                Appraisal costs
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                value={formData.appraisalCosts}
                onChange={(value, err) =>
                  onChange('appraisalCosts', value, err)
                }
                id="appraisalCosts"
                name="appraisalCosts"
                onWheel={(e) => e.target.blur()}
                required
              />
            </Box>
          </Flex>
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Closing costs
          </Heading>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="originationFee">
                Origination fee
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.originationFee}
                onChange={(value, err) =>
                  onChange('originationFee', value, err)
                }
                id="originationFee"
                name="originationFee"
                required
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="titleFee">
                Title fee
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.titleFee}
                onChange={(value, err) => onChange('titleFee', value, err)}
                id="titleFee"
                name="titleFee"
                required
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="recordingAndTax">
                Recording & Tax certification fee
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={formData.recordingAndTax}
                onChange={(value, err) =>
                  onChange('recordingAndTax', value, err)
                }
                id="recordingAndTax"
                name="recordingAndTax"
                required
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="estimatedClosingCosts">
                Estimated closing costs
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={calcFields.estimatedClosingCosts}
                id="estimatedClosingCosts"
                name="estimatedClosingCosts"
                required
                disabled
              />
            </Box>
          </Flex>
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Total Estimated Funds to/from Borrower
          </Heading>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="totalLoanAmount">
                Total Loan Amount
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={lead?.loanAmountDesiredForRenovation}
                id="totalLoanAmount"
                name="totalLoanAmount"
                required
                disabled
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="payOffAmount">
                Payoff Amount
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={calcFields.payOffAmount}
                id="payOffAmount"
                name="payOffAmount"
                required
                disabled
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="estimatedTotalClosingCost">
                Estimated Total Closing costs
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={calcFields.estimatedClosingCosts}
                id="estimatedTotalClosingCost"
                name="estimatedTotalClosingCost"
                required
                disabled
              />
            </Box>
          </Flex>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="netEstimatedFunds">
                Net Total Estimated Funds to/from Borrower
              </Label>
              <CurrencyField
                icon
                min={0}
                limit={9}
                noSpecialCharacters
                value={calcFields.netEstimatedFunds}
                id="netEstimatedFunds"
                name="netEstimatedFunds"
                required
                disabled
              />
            </Box>
          </Flex>
        </Section>

        <Section>
          <Heading small left mb={[24, 10]}>
            Send Pre-Application Worksheet to
          </Heading>
          <Flex mb={24} flexDirection={['column', 'row']} flexWrap="wrap">
            <Box width={[1, 1]} pr={[0, 3]} mb={[24, 0]}>
              <Label small htmlFor="email">
                Email Address
              </Label>
              <TextField
                renofiEmail
                icon="email"
                value={formData.email}
                onChange={(value, err) => onChange('email', value, err)}
                id="email"
                name="email"
                type="email"
                placeholder="@renofi.com"
                required
              />
            </Box>
          </Flex>
        </Section>

        <Footer>
          <Flex justifyContent="flex-end">
            <Button xSmall secondary onClick={onCancel}>
              Cancel
            </Button>
            <Button
              xSmall
              css={{
                marginLeft: '20px',
                backgroundColor: !isFormValid() ? lightGray : blue,
                color: !isFormValid() ? gray : fadeGray,
              }}
              onClick={onSubmit}
              loading={isLoading}
              disabled={!isFormValid()}>
              Send
            </Button>
          </Flex>
        </Footer>
      </Wrapper>
    </>
  );
};

PreApplicationForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  lead: PropTypes.object.isRequired,
  calcValues: PropTypes.object.isRequired,
  isHeloc: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  calcFields: PropTypes.object.isRequired,
  isFormValid: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PreApplicationForm;
