import {getItem} from '@renofi/utils';

import {ONE_MINUTE} from '../../../modules/src/AuthApolloClient/constants';

import getRefreshTokenInterval from './getRefreshTokenInterval';

export default () => {
  const jwt = getItem('jwt');
  if (!jwt) {
    return false;
  }

  const refreshInterval = getRefreshTokenInterval(jwt);
  return refreshInterval <= ONE_MINUTE;
};
