import {gql} from '@apollo/client';

export const SEARCH_BY_BORROWER = gql`
  query searchByBorrower($query: String!) {
    searchByBorrower(query: $query) {
      lead {
        email
        id
        firstName
        lastName
        city
        state
        zipCode
        phone
        prequalConditions
        prequalCompletedAt
        prequalResult
      }
      lender {
        name
      }
    }
  }
`;
