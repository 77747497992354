import React from 'react';

import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import {cache} from 'api/cache';

import initPolyfills from '@renofi/utils/src/polyfills';
import {AuthApolloClient} from '@renofi/modules';

import './index.css';
import {initAnalytics} from './analytics';
import App from './App';

initPolyfills();
initAnalytics();

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);

root.render(
  <React.StrictMode>
    <Router>
      <AuthApolloClient
        analyticsPrefix="Advisors"
        cache={cache}
        redirectTo="/search">
        <App />
      </AuthApolloClient>
    </Router>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from './reportWebVitals';
// reportWebVitals();
