import React from 'react';

import {Flex} from 'rebass';

import {Loader} from '@renofi/components';
import {red} from '@renofi/utils/src/colors';

const LoadingSpinner = () => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{width: '100vw', height: '100vh'}}>
      <Loader color={red} size={2} />
    </Flex>
  );
};

export default LoadingSpinner;
