import React from 'react';

import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';
import {isFinite} from 'lodash';
import {PatternFormat} from 'react-number-format';
import {useHistory} from 'react-router-dom';

import {
  capitalizeFirstLetter,
  toCurrency,
  toPercent,
} from '@renofi/utils/src/format';
import {enums} from '@renofi/api';
import {Panel, StatusBadge, Toggle} from '@renofi/components';
import {
  getPropertyStatus,
  getPersonalStatus,
  getRenovationStatus,
  combineIncomeAndDebtStatus,
} from '@renofi/utils/src/status';

import {History, Value, BoolValue} from '../../components';
import {scenarioVar} from '../../api/cache/scenario';

import SummaryTitle from './SummaryTitle';
import SummaryDetails from './SummaryDetails';
import {Column, Columns} from './styled';
import CreditScore from './CreditScore';
import {getLoanSubject} from './utils';

function Summary({onLoanProductClick}) {
  const history = useHistory();
  const scenario = useReactiveVar(scenarioVar);
  const {lead} = scenario;
  const {monthlyDebt, monthlyIncome} = lead;
  const dti = monthlyDebt / monthlyIncome;

  function onEditLocation() {
    history.push(`/${lead.id}/estimate`);
  }

  return (
    <Panel
      dark
      background="white"
      title={<SummaryTitle onLoanProductClick={onLoanProductClick} />}>
      <SummaryDetails />
      <Columns>
        <Column>
          <Value>
            <h2>Personal</h2>
            <StatusBadge text={lead.creditScore && getPersonalStatus(lead)} />
          </Value>

          <CreditScore lead={lead} />

          <Value
            label="Email"
            style={{fontSize: '1em', overflowWrap: 'break-word'}}>
            {lead.email}
          </Value>

          <Value label="Phone">
            {lead.phone ? (
              <PatternFormat
                format="(###) ###-####"
                displayType="text"
                value={lead.phone}
              />
            ) : (
              '-'
            )}
          </Value>
        </Column>

        <Column>
          <Value>
            <h2>Property</h2>
            <StatusBadge
              text={lead.propertyUseType && getPropertyStatus(lead)}
            />
          </Value>

          <Value label="Property status">{getLoanSubject(lead)}</Value>
          <Value
            css={{textTransform: 'capitalize'}}
            label="Location"
            onEdit={onEditLocation}
            status={lead.status.state}>
            {lead.streetAddress}, {lead.city}, {lead.state} {lead.zipCode}
            <History items={lead.history.state} />
          </Value>

          <Value
            label="Property type"
            status={lead.status.propertyStructureType}>
            {enums.get(lead.propertyStructureType)}
            <History
              items={lead.history.propertyStructureType}
              formatFn={(value) => enums.get(value)}
            />
          </Value>

          <Value label="Property use" status={lead.status.propertyUseType}>
            {enums.get(lead.propertyUseType)}
            <History
              items={lead.history.propertyUseType}
              formatFn={(value) => enums.get(value)}
            />
          </Value>

          <Toggle show={lead.estimatedProjectSize}>
            <Value
              label="Project size"
              status={lead.status.estimatedProjectSize}>
              {capitalizeFirstLetter(enums.get(lead.estimatedProjectSize))}
              <History
                items={lead.history.estimatedProjectSize}
                formatFn={(value) => enums.get(value)}
              />
            </Value>
          </Toggle>

          <Value label="Current LTV">
            {toPercent(lead.firstMortgageBalance / lead.homeValue, 2)}
          </Value>
        </Column>

        <Column>
          <Value>
            <h2>Income / Debt</h2>
            <StatusBadge
              text={lead.estimatedDti && combineIncomeAndDebtStatus(lead)}
            />
          </Value>

          <Value label="Annual combined pre-tax">
            ${toCurrency(lead.annualIncomePretax)}
          </Value>

          <Value label="Monthly income">
            ${toCurrency(lead.monthlyIncome)}
          </Value>

          <Value
            label="Estimated current DTI"
            status={lead.status.estimatedDti}>
            {isFinite(dti) ? toPercent(dti) : '-'}
            <History
              items={lead.history.estimatedDti}
              formatFn={(value) => toPercent(value)}
            />
          </Value>

          <Value
            label="Bankruptcy / foreclosure"
            status={lead.status.recentBankruptcy}>
            <BoolValue value={lead.recentBankruptcy} />
            <History
              items={lead.history.recentBankruptcy}
              formatFn={(value) => <BoolValue small value={value} />}
            />
          </Value>

          {lead.hasLateMortgagePayments && (
            <Value
              label="Late mortgage payment"
              status={lead.status.hasLateMortgagePayments}>
              <BoolValue value={lead.hasLateMortgagePayments} />
              <History
                items={lead.history.hasLateMortgagePayments}
                formatFn={(value) => <BoolValue small value={value} />}
              />
            </Value>
          )}
        </Column>

        <Column>
          <Value>
            <h2>Renovation</h2>
            <StatusBadge
              text={lead.renovationPerformedByType && getRenovationStatus(lead)}
            />
          </Value>

          <Value label="Expected start date">
            {enums.get(lead.renovationIdealStart)}
            <History
              items={lead.history.renovationIdealStart}
              formatFn={(value) => enums.get(value)}
            />
          </Value>

          <Value
            label="Performing the renovation"
            status={lead.status.renovationPerformedByType}>
            {enums.get(lead.renovationPerformedByType)}
            <History
              items={lead.history.renovationPerformedByType}
              formatFn={(value) => enums.get(value)}
            />
          </Value>
        </Column>
      </Columns>
    </Panel>
  );
}

Summary.propTypes = {
  onLoanProductClick: PropTypes.func.isRequired,
};

export default Summary;
