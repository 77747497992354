import React from 'react';

import PropTypes from 'prop-types';

import {enums} from '@renofi/api';

import {History, Value} from '../../../components';

const CreditScore = ({lead}) => {
  return (
    <>
      <Value
        status={lead.status.selfReportedCreditScore}
        label={<>Self reported credit score range </>}>
        {enums.get(lead.selfReportedCreditScore)}
        <History
          items={lead.history.selfReportedCreditScore}
          formatFn={(value) => enums.get(value)}
        />
      </Value>
    </>
  );
};

CreditScore.propTypes = {
  lead: PropTypes.object.isRequired,
};

export default CreditScore;
