/* eslint-disable max-lines */
import {ADMIN_RENOVATION_SCENARIO} from './adminRenovationScenario';

export default {
  request: {
    query: ADMIN_RENOVATION_SCENARIO,
    variables: {
      id: '80740345-badd-428b-88f6-0fe69643ee49',
    },
  },
  result: {
    data: {
      adminRenovationScenario: {
        loanPassLink: 'https://fakeloanpass.link',
        lead: {
          id: '80740345-badd-428b-88f6-0fe69643ee49',
          calculatorToken: '6rc9yv-AhaVMi',
          crmLeadId: '00Q21000008a0cTEAQ',
          streetAddress: '123 Street',
          county: 'Ohio',
          estimatedHomeValue: 450000,
          crmOpportunityId: '00Q21000008a0cXWPO',
          additionalRenovationDetails: null,
          isSelfEmploymentIncomeStable: true,
          firstName: 'James',
          lastName: 'Bond',
          email: 'james@renofi.dev',
          city: 'Albany',
          state: 'New York',
          inLicensedState: true,
          hasNewsletterConsent: true,
          zipCode: '12222',
          afterRenovatedValue: 600000,
          homeValue: 400000,
          firstMortgageBalance: 250000,
          firstMortgageRate: 4.55,
          renovationCost: null,
          loanAmount: 245000,
          loanAmountDesiredForRenovation: 245000,
          creditScore: 'one',
          selfReportedCreditScore: 'five',
          medal: 'bronze',
          monthlyIncomeAlimony: 0,
          monthlyIncomeChildSupport: null,
          monthlyIncome: 13000,
          monthlyDebt: 2000,
          notMyScoreReportedAt: '2021-06-30T07:49:13Z',
          employmentStatus: null,
          phone: '1231231231',
          stars: 1,
          estimatedDti: 0.25,
          arvCltv: 0.833333333333333,
          peakCltv: 1.25,
          prequalStartedAt: '2021-06-30T08:59:52Z',
          prequalCompletedAt: '2021-07-02T13:45:57Z',
          prequalConditions: ['credit_score'],
          prequalFailures: [],
          prequalResult: 'CONDITION',
          propertyPurchasePrice: 423000,
          alimonyPayment: null,
          childSupportPayment: null,
          isLivingInPropertyDuringReno: null,
          loanSubject: 'renovate_home_being_purchased',
          stageOfPurchase: 'offer_accepted',
          alreadyStartedPropertyStateDetails: null,
          annualIncomePretax: 240000,
          creditCardPayment: null,
          hasLateMortgagePayments: false,
          primaryMortgagePayment: 500,
          secondaryMortgagePayment: null,
          secondMortgageBalance: null,
          personalLoanPayment: null,
          propertyStructureType: 'single_family',
          propertyUseType: 'primary_residence',
          recentBankruptcy: false,
          repaymentPeriod: 20,
          realCreditScoreNumber: 900,
          renovationIdealStart: 'in_next_2_months',
          renovationPerformedByType: 'hiring',
          studentLoanPayment: null,
          studentLoanBalance: null,
          studentLoanPrePandemicPayment: null,
          subcontractorsSecured: true,
          hasRenovationEstimate: true,
          subcontractorsIdentified: true,
          totalMortgageBalance: null,
          isSubcontractingMany: false,
          isLicensedContractor: false,
          vehiclePayment: null,
          createdAt: '2021-06-30T07:49:13Z',
          updatedAt: '2021-07-08T14:48:27Z',
          workingLongTerm: true,
          estimatedProjectSize: 'large',
          experianScore: null,
          unsecuredBalance: null,
          unsecuredRatio: null,
          propertyUnitCount: 1,
          estimatedDownpayment: 55000,
          propertyOwnershipStatus: 'owned',
          teardownOfExistingStructure: true,
          estimatedDaysUntilClosingPurchase: 'thirty',
          demolishExistingStructure: true,
          preExistingStructuresOnLandPresent: true,
          anythingLeftFromOriginalStructure: true,
          changeHistory: [
            {
              createdAt: '2021-07-02T13:45:06Z',
              field: 'renovation_performed_by_type',
              result: 'FAIL',
              value: 'alone',
            },
            {
              createdAt: '2021-06-30T09:01:18Z',
              field: 'renovation_performed_by_type',
              result: 'PASS',
              value: 'hiring',
            },
            {
              createdAt: '2021-07-03T13:46:00Z',
              field: 'hasRenovationEstimate',
              result: 'FAIL',
              value: 'f',
            },
            {
              createdAt: '2021-07-03T13:46:00Z',
              field: 'subcontractorsSecured',
              result: 'FAIL',
              value: 'f',
            },
            {
              createdAt: '2021-07-03T13:46:00Z',
              field: 'subcontractorsIdentified',
              result: 'FAIL',
              value: 'f',
            },
            {
              createdAt: '2021-07-03T13:46:00Z',
              field: 'isLicensedContractor',
              result: 'PASS',
              value: 't',
            },
          ],
        },
        lender: {
          name: 'Quorum FCU',
          code: 'quorum',
          logoUrl: null,
        },
        loanProductMatches: [],
      },
    },
  },
};
