import styled from '@emotion/styled/macro';
import {Box} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Grid = styled(Box)(
  mediaQuery({
    padding: '20px 15px 0 15px',
    display: 'grid',
    gridTemplateColumns: ['100%', '25% 25% 25% 25%'],
    gridGap: 30,
    marginRight: 90, // 3x gridGap
  }),
);
