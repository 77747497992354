import styled from '@emotion/styled/macro';
import {Flex} from 'rebass';

import {mediaQuery} from '@renofi/utils';

export const Columns = styled(Flex)(
  mediaQuery({
    margin: '0 -15px -15px -15px',
    background: 'white',
    flexDirection: ['column', 'row'],
    justifyContent: 'flex-start',
    borderRadius: '0 0 15px 15px',
  }),
);

export const Column = styled(Flex)(
  mediaQuery({
    flexDirection: 'column',
    borderRight: ['none', '1px solid #D8D8D8'],
    borderBottom: ['1px solid #D8D8D8', 'none'],
    width: ['100%', '25%'],
    padding: '20px 35px',
    '&:last-of-type': {
      border: 'none',
    },
    '> div': {
      marginBottom: 30,
      '&:last-of-type': {
        marginBottom: 10,
      },
    },
  }),
);
