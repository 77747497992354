import React from 'react';

import PropTypes from 'prop-types';

import Value from '../Value';

function BoolValue({value, small, ...props}) {
  if (value === null || value === undefined || value === 0) return '-';

  function getValue() {
    if (value === 't') return 'Yes';
    if (value === 'f') return 'No';
    return value ? 'Yes' : 'No';
  }

  return (
    <Value small={small} {...props}>
      {getValue()}
    </Value>
  );
}

BoolValue.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  small: PropTypes.bool,
};

export default BoolValue;
