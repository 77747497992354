import React, {useCallback, useEffect, useState} from 'react';

import toast from 'react-hot-toast';
import {useParams, useHistory, useRouteMatch} from 'react-router-dom';
import {every, get, isNil} from 'lodash';
import {useReactiveVar} from '@apollo/client';

import {isEmail, isRenofiEmail} from '@renofi/utils/src/validate';

import {scenarioVar} from '../../api/cache/scenario';
import {
  useCurrentUser,
  useRequestWorksheetHomeEquityLineOfCredit,
  useRequestWorksheetHomeEquity,
} from '../../api';
import {
  getAppraisalCosts,
  calcEstimatedClosingCosts,
  calcEstimatedInterestRateHeloc,
  calcEstimatedMonthlyTotalPayment,
  calcNetEstimatedFunds,
  limitToThreeDp,
  getOriginationFee,
} from '../../utils/calcPawFields';
import getLoanOptionsCalculations from '../../utils/getLoanOptionsCalculations';
import {getMissingSalesForceFields} from '../../utils/getMissingSalesForceFields';

import FailedAlert from './FailedAlert';
import PreApplicationForm from './PreApplicationForm';

const PreApplication = () => {
  const {url} = useRouteMatch();
  const {id} = useParams();
  const history = useHistory();
  const scenario = useReactiveVar(scenarioVar);
  const {lead, productOptions} = scenario;
  const product = productOptions.find((product) => product.id === id);
  const isHeloc =
    product?.productType === 'renofi_heloc' ||
    product?.productType === 'traditional_heloc';
  const FAILED_TEXT = 'Failed to Send Pre-Application Worksheet.';

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [calcFields, setCalcFields] = useState({});
  const [calcValues, setCalcValues] = useState({});

  const {isMissingSalesForceFields, currentUser} = useCurrentUser();
  const {requestWorksheetHomeEquityLineOfCredit, loading: loadingHeloc} =
    useRequestWorksheetHomeEquityLineOfCredit();
  const {requestWorksheetHomeEquity, loading} = useRequestWorksheetHomeEquity();

  useEffect(() => {
    if (product && lead) {
      const result = getLoanOptionsCalculations(
        product.calculationsByRate,
        lead.loanAmountDesiredForRenovation,
      );
      setCalcValues(result);
      setFormData({
        appraisalCosts: getAppraisalCosts(lead?.state) || '1000',
        originationFee: getOriginationFee(product?.lender?.code),
        secondMortgagePayment: lead?.secondaryMortgagePayment || '0',
        firstMortgagePayment: lead?.primaryMortgagePayment || '0',
      });
    }
  }, [product, lead]);

  const isFormValid = () => {
    const checkInterestRate = isHeloc
      ? formData.primeIndexStartRate && formData.margin
      : formData.estimatedInterestRate;
    return (
      every(errors, isNil) &&
      checkInterestRate &&
      formData.firstMortgagePayment &&
      formData.secondMortgagePayment &&
      formData.hazardInsurance &&
      formData.realEstateTaxes &&
      formData.appraisalCosts &&
      formData.originationFee &&
      formData.titleFee &&
      formData.recordingAndTax &&
      isEmail(formData.email) &&
      isRenofiEmail(formData.email)
    );
  };

  const onChange = useCallback(
    (key, value, err) => {
      const newData = {...formData, [key]: value};

      const estimatedMonthlyTotalPayment =
        calcEstimatedMonthlyTotalPayment(newData);

      const estimatedInterestRateHeloc =
        calcEstimatedInterestRateHeloc(newData);

      const estimatedClosingCosts = calcEstimatedClosingCosts(newData);

      const payOffAmount = lead.secondMortgageBalance;

      const interestRate = isHeloc
        ? estimatedInterestRateHeloc
        : formData.estimatedInterestRate;

      const netEstimatedFunds = calcNetEstimatedFunds({
        loanAmountDesiredForRenovation: lead?.loanAmountDesiredForRenovation,
        payOffAmount,
        estimatedClosingCosts,
      });

      setCalcFields({
        ...calcFields,
        interestRate,
        payOffAmount,
        estimatedInterestRateHeloc,
        estimatedMonthlyTotalPayment,
        estimatedClosingCosts,
        netEstimatedFunds,
      });

      setFormData({
        ...newData,
        margin: limitToThreeDp(newData.margin),
        primeIndexStartRate: limitToThreeDp(newData.primeIndexStartRate),
        estimatedInterestRate: limitToThreeDp(newData.estimatedInterestRate),
      });
      setErrors({...errors, [key]: err});
    },
    [formData],
  );

  const variables = {
    scenarioId: scenario.id,
    loanProductId: product?.id,
    homeownerName: `${lead.firstName} ${lead.lastName}`,
    propertyZipCode: lead.zipCode,
    email: formData.email,
    lenderName: product?.lender?.name,
    estimatedAfterRenovationValue: lead.afterRenovatedValue,
    loanAmount: lead?.loanAmountDesiredForRenovation,
    rateType: product?.rateType,
    intrestRate: calcFields.interestRate,
    term: product?.repaymentPeriod ? `${product.repaymentPeriod}-Year` : '-',
    appraisal: {amount: Number(formData.appraisalCosts)},
    closingCosts: {
      originationFee: Number(formData.originationFee),
      titleFees: Number(formData.titleFee),
      taxCertifications: Number(formData.recordingAndTax),
      estimatedTotal: calcFields.estimatedClosingCosts,
    },
    estimatedMonthlyPayment: {
      firstMortgagePayment: Number(formData.firstMortgagePayment),
      secondMortgagePayment: Number(formData.secondMortgagePayment),
      hazardInsurence: Number(formData.hazardInsurance),
      realEstateTaxes: Number(formData.realEstateTaxes),
      hoaInsurence: Number(formData.hoaInsurance),
      floodInsurance: Number(formData.floodInsurance),
      estimatedTotalMonthlyPayment: calcFields.estimatedMonthlyTotalPayment,
    },
    totalEstFundsBorrower: {
      totalLoanAmount: Number(lead.loanAmountDesiredForRenovation),
      payoffAmount: Number(calcFields.payOffAmount),
      estimatedClosingCosts: Number(calcFields.estimatedClosingCosts),
      netTotal: Number(calcFields.netEstimatedFunds),
    },
  };

  const onSubmit = useCallback(async () => {
    try {
      if (isMissingSalesForceFields) {
        const {errorMessage} = getMissingSalesForceFields(currentUser);
        toast.error(errorMessage);
        return;
      }
      const key = isHeloc
        ? 'requestWorksheetHomeEquityLineOfCredit'
        : 'requestWorksheetHomeEquity';
      const mutation = isHeloc
        ? requestWorksheetHomeEquityLineOfCredit
        : requestWorksheetHomeEquity;
      const response = await mutation({variables});
      const isSuccess = get(response, `data.${key}.success`, false);

      if (!isSuccess) {
        return toast.error(FAILED_TEXT);
      }

      history.push(`${url}/success`, {email: formData.email});
    } catch (e) {
      toast.error(FAILED_TEXT);
    }
  }, [formData]);

  return (
    <>
      <PreApplicationForm
        formData={formData}
        product={product}
        lead={lead}
        calcValues={calcValues}
        onChange={onChange}
        isHeloc={isHeloc}
        onSubmit={onSubmit}
        onCancel={() => history.goBack()}
        calcFields={calcFields}
        isFormValid={isFormValid}
        isLoading={loading || loadingHeloc}
      />
      <FailedAlert />
    </>
  );
};

export default PreApplication;
