import React from 'react';

import {Link} from 'rebass';
import {useReactiveVar} from '@apollo/client';

import {getSalesforceUrl} from '@renofi/utils';
import {getEnvironmentUrl} from '@renofi/utils';

import {Label} from '../../../components';
import {scenarioVar} from '../../../api/cache/scenario';

import {DetailsItem, DetailsWrapper, DetailsValue} from './styled';
import {ReactComponent as LinkIcon} from './img/ling.svg';

function SummaryDetails() {
  const scenario = useReactiveVar(scenarioVar);
  const {lead, lender, loanPassLink} = scenario;
  const {name} = lender;
  const calculatorAppUrl = `${getEnvironmentUrl(
    'calculator',
    '4004',
  )}/renovation/${lead.calculatorToken}?test=true`;
  const salesforceUrl = getSalesforceUrl(
    lead.crmOpportunityId || lead.crmLeadId,
  );

  return (
    <DetailsWrapper>
      <DetailsItem>
        <Label>Lender under consideration</Label>
        <DetailsValue>{name ? name : '-'}</DetailsValue>
      </DetailsItem>

      <DetailsItem>
        <Label>Personalized calculator</Label>
        {lead.calculatorToken ? (
          <Link
            href={calculatorAppUrl}
            display="flex"
            alignItems="center"
            target="_blank">
            View calculator
            <LinkIcon style={{marginLeft: 8}} />
          </Link>
        ) : (
          '-'
        )}
      </DetailsItem>

      <DetailsItem>
        <Label>Salesforce</Label>
        {salesforceUrl && (
          <Link
            href={salesforceUrl}
            display="flex"
            alignItems="center"
            target="_blank">
            View deal
            <LinkIcon style={{marginLeft: 8}} />
          </Link>
        )}
      </DetailsItem>

      <DetailsItem>
        <Label>LoanPASS</Label>
        {loanPassLink && (
          <Link
            href={loanPassLink}
            display="flex"
            alignItems="center"
            target="_blank">
            View options
            <LinkIcon style={{marginLeft: 8}} />
          </Link>
        )}
      </DetailsItem>
    </DetailsWrapper>
  );
}

export default SummaryDetails;
