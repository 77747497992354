import React, {useState} from 'react';

import {Box} from 'rebass';
import PropTypes from 'prop-types';

import {formatDate} from '@renofi/utils/src/date';
import {formatAMPM} from '@renofi/utils/src/time';

import HistoryItem from './HistoryItem';
import {ReactComponent as TickIcon} from './img/tick.svg';
import {TickWrapper} from './styled';

function History({items = [], formatFn = (v) => v}) {
  const [open, setOpen] = useState(false);

  if (items.length === 0) return null;
  return (
    <Box className="history-box" mt="5px" onClick={() => setOpen(!open)}>
      {items.map((item, index) => {
        return (
          <HistoryItem
            css={{display: !open && index !== 0 ? 'none' : 'flex'}}
            key={`${item.createdAt}${item.field}`}
            status={item.result}>
            <div>
              {index === 0 ? (
                <>
                  Previous selection
                  <br />
                </>
              ) : (
                ''
              )}
              {formatDate(new Date(item.createdAt))},{' '}
              {formatAMPM(new Date(item.createdAt))}
              <br />
              <strong>{formatFn(item.value)}</strong>
            </div>
            {index === 0 && items.length > 1 ? (
              <TickWrapper open={open}>
                <TickIcon />
              </TickWrapper>
            ) : null}
          </HistoryItem>
        );
      })}
    </Box>
  );
}

History.propTypes = {
  items: PropTypes.array,
  formatFn: PropTypes.func,
};

export default History;
