import adminRenovationScenarioMock from './adminRenovationScenario.mock';

const posMock = JSON.parse(JSON.stringify(adminRenovationScenarioMock));
const adminRenovationScenario = posMock.result.data.adminRenovationScenario;
adminRenovationScenario.lead = {
  ...adminRenovationScenario.lead,
  city: 'Philadelphia',
  state: 'Pennsylvania',
  zipCode: '19019',
  creditScore: 'two',
  loanSubject: 'renovate_current_home',
  stageOfPurchase: null,
  employmentStatus: 'employee',
  renovationPerformedByType: 'hiring',
  subcontractorsSecured: true,
  hasRenovationEstimate: true,
  subcontractorsIdentified: true,
  isSubcontractingMany: false,
  isLicensedContractor: false,
};

posMock.result.data.adminRenovationScenario = adminRenovationScenario;
export default posMock;
